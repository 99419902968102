import React from 'react'
import Layout from '../components/Layout/Layout'
import { PiStudent , PiClover ,  PiHeart } from "react-icons/pi";
import { IoBusinessOutline } from "react-icons/io5";
import { FaGlassMartiniAlt,FaChild } from "react-icons/fa";

const Services = () => {
    return (
        <Layout>
                    <div className='main-title'><div className='title'>Best Life Changing Solutions in Just One Call</div></div>
<div className='main-title-down-image'><img src='\images\title-border.webp' alt='Not Found'/></div>

<div className='main-justcall-container'>
Who would have thought that the answers to your life’s challenges lie in a single call with the right person? Out of all the calls you make in your life, only a few can really help solve your problems. Here at Numberology.com can give you solutions to all these problems in just one call........
</div>

<section class="articles">
  <article>
    <div class="article-wrapper">
      <figure>
    <p className='card-icon'>  <PiStudent /></p>
      </figure>
      <div class="article-body">
        <h2>Careere</h2>
        <p>
        Many students get confused and want to know what to do after exams, about jobs, and the future. Talk to Numberology who can assist you in making the right career choices by analyzing your birth chart and planetary influences. He will offer insights into your strengths, weaknesses, & potential career paths.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'> <PiClover /></p>      </figure>
      <div class="article-body">
        <h2>Love & Relationship</h2>
        <p>
        Looking for the best love horoscope compatibility with your partner? While relationship issues may arise, accurate horoscope matching & predictions can provide insights for a successful, prosperous bond. One counseling call with our Numberology can reveal the potential of your relationship.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <PiHeart /></p>      </figure>
      <div class="article-body">
        <h2>Health</h2>
        <p>
        Vedic Numberology predictions, can forecast health issues or injuries before they appear in your body. This foresight arises from your kundli's impact on various life aspects, guided by celestial houses. When unfavorable planets affect these areas in your Kundli by date of birth and time of birth.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
</section>
<br/>
<section class="articles">
  <article>
    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <IoBusinessOutline />      </p>        </figure>
      <div class="article-body">
        <h2>Business</h2>
        <p>
        When you're starting a business or facing big choices, it's like being on a see-saw, balancing between success and failure. To boost your chances of business success, getting accurate insights from Numberology is important. Consulting an numberolgoy can help you address and overcome your business problems.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <FaGlassMartiniAlt /></p>        </figure>
      <div class="article-body">
        <h2>Marriage</h2>
        <p>
        For centuries in India, the practice of Kundli Milan for marriage is very important. Talk to Numberology for horoscope matching of couples. Get Gun Milan, Mangal Dosha, & compatibility analysis. This thorough process aids in identifying the most suitable life partner through couple Kundli Milan.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <FaChild /></p>        </figure>
      <div class="article-body">
        <h2>Child</h2>
        <p>
        The ancient Indian Vedic Numberology examines the horoscopes of both partners, especially for those interested in knowing about having children and even predicting the child's name. By studying the 12 houses in a person's chart, Numberology offers accurate solutions for issues related to childbirth.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
</section>
        </Layout >
    )
}

export default Services
