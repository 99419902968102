import React , { useState, useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import { PiStudent , PiClover ,  PiHeart } from "react-icons/pi";
import { IoBusinessOutline } from "react-icons/io5";
import { FaGlassMartiniAlt,FaChild } from "react-icons/fa";
import { VscVerified } from "react-icons/vsc";



const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  //Block Inspact None

  const disableRightClick = (event) => {
    event.preventDefault();
  };
  document.addEventListener("contextmenu", disableRightClick);

  // Disable Developer Tools
  const disableDevTools = (event) => {
    if (
      event.ctrlKey &&
      (event.key === "i" || event.key === "I" || event.key === "u" || event.key === "U") // Ctrl+Shift+I or Ctrl+U
    ) {
      event.preventDefault();
    }
    if (event.key === "F12") {
      event.preventDefault();
    }
  };
  document.addEventListener("keydown", disableDevTools);

    return (    
        <Layout>

<div className="App">
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="content">
          


<div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="\images\bg1.png" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="\images\NUMBEROLOGY-chek-banner.png" class="d-block w-100 " alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="\images\NUMBEROLOGY-33-banner.png" class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<br/>
<br/>
<br/>

<div className='Your-Guide-To-Life'>
<div className='your-guide-text-secton'>
<p className='your-guide-text-title'>Numberology: Your Guide To Life's Journey & Path To Success!</p>
<p className='your-guide-text-content'>We believe Numberology is pure science, and we aim to create such a social impact of the organization on you that science and Numberology through its resources of the numberolgoy chart etc,. As we dive into our 20th year, we strive to build a community where we are a part of your daily routine right from our Numberology by date of birth, name, time, palm reading, and face reading, to all other online astrology, numerology, palmistry services.</p>

<p className='your-guide-years-exp'>
<VscVerified className='ygyexp-icon'/>
<div className='ygyexp-contse'><p className='ygyexp-ttsjks-title'>20+ Years Of Legacy</p>
</div>
</p>
</div>

<div className='your-guide-image-section'>
<img src='\images\Graphic-1.png' alt='Not Found'/>
</div>
</div>

<div className='Your-Guide-To-Life'>
<div className='your-guide-text-secton'>
<p className='your-guide-text-title'>Book Consultation Call from the Best Numerologist in India!</p>
<p className='your-guide-text-content'>We believe Numberology is pure science, and we aim to create such a social impact of the organization on you that science and numberolgy through its resources of the numerology chart etc,. As we dive into our 20th year, we strive to build a community where we are a part of your daily routine right from our astrology by date of birth, name, time, palm reading, and face reading, to all other online astrology, numerology, palmistry services</p>
</div>

<div className='your-guide-image-section'>
<img src='\images\Graphic-2.png' alt='Not Found'/>
</div>
</div>

<div className='main-exp-hlegro'>
  <div className='box-exp-hlegro-redios'>
   <b>
   <p className='num-exp-hlegro'>20+</p>
   <p className='sepl-exp-hlegro'>Year's Of Legacy</p>
   </b>
  </div>

  <div className='box-exp-hlegro-redios'>
   <b>
   <p className='num-exp-hlegro'>50k+</p>
   <p className='sepl-exp-hlegro'>Students Enrolled</p>
   </b>
  </div>

  <div className='box-exp-hlegro-redios'>
   <b>
   <p className='num-exp-hlegro'>100k+</p>
   <p className='sepl-exp-hlegro'>Consultations Given</p>
   </b>
  </div>

  <div className='box-exp-hlegro-redios'>
   <b>
   <p className='num-exp-hlegro'>5+</p>
   <p className='sepl-exp-hlegro'>Languages For Reports</p>
   </b>
  </div>

  <div className='box-exp-hlegro-redios'>
   <b>
   <p className='num-exp-hlegro'>20+</p>
   <p className='sepl-exp-hlegro'>Awards in the field of Occult</p>
   </b>
  </div>
</div>


                <div className='main-title'><div className='title'>Best Life Changing Solutions in Just One Call</div></div>
<div className='main-title-down-image'><img src='\images\title-border.webp' alt='Not Found'/></div>

<div className='main-justcall-container'>
Who would have thought that the answers to your life’s challenges lie in a single call with the right person? Out of all the calls you make in your life, only a few can really help solve your problems. Here at Numberology.com can give you solutions to all these problems in just one call........
</div>

<section class="articles">
  <article>
    <div class="article-wrapper">
      <figure>
    <p className='card-icon'>  <PiStudent /></p>
      </figure>
      <div class="article-body">
        <h2>Career</h2>
        <p>
        Many students get confused and want to know what to do after exams, about jobs, and the future. Talk to Numberology who can assist you in making the right career choices by analyzing your birth chart and planetary influences. He will offer insights into your strengths, weaknesses, & potential career paths.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'> <PiClover /></p>      </figure>
      <div class="article-body">
        <h2>Love & Relationship</h2>
        <p>
        Looking for the best love horoscope compatibility with your partner? While relationship issues may arise, accurate horoscope matching & predictions can provide insights for a successful, prosperous bond. One counseling call with our Numberology can reveal the potential of your relationship.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <PiHeart /></p>      </figure>
      <div class="article-body">
        <h2>Health</h2>
        <p>
        Vedic Numberology predictions, can forecast health issues or injuries before they appear in your body. This foresight arises from your kundli's impact on various life aspects, guided by celestial houses. When unfavorable planets affect these areas in your Kundli by date of birth and time of birth.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
</section>
<br/>
<section class="articles">
  <article>
    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <IoBusinessOutline />      </p>        </figure>
      <div class="article-body">
        <h2>Business</h2>
        <p>
        When you're starting a business or facing big choices, it's like being on a see-saw, balancing between success and failure. To boost your chances of business success, getting accurate insights from Numberology is important. Consulting an numberolgoy can help you address and overcome your business problems.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <FaGlassMartiniAlt /></p>        </figure>
      <div class="article-body">
        <h2>Marriage</h2>
        <p>
        For centuries in India, the practice of Kundli Milan for marriage is very important. Talk to Numberology for horoscope matching of couples. Get Gun Milan, Mangal Dosha, & compatibility analysis. This thorough process aids in identifying the most suitable life partner through couple Kundli Milan.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
      <p className='card-icon'>  <FaChild /></p>        </figure>
      <div class="article-body">
        <h2>Child</h2>
        <p>
        The ancient Indian Vedic Numberology examines the horoscopes of both partners, especially for those interested in knowing about having children and even predicting the child's name. By studying the 12 houses in a person's chart, Numberology offers accurate solutions for issues related to childbirth.        </p>
        <a className='btn-card' href='/Contact'>Book Now</a>
      </div>
    </div>
  </article>
</section>
<br/>
<br/>
<div className='main-title'><div className='title'>WHY US</div></div>
<div className='main-title-down-image'><img src='\images\title-border.webp' alt='Not Found'/></div>

<div className='main-whyus-container'>
    <div className='main-whyus-box'>
        <p>Satisfaction Guaranteed</p>
    </div>
    <div className='main-whyus-box'>
        <p>Experienced & Knowledgeable</p>
    </div>
    <div className='main-whyus-box'>
        <p>Cost Effective Remedies</p>
    </div>
    <div className='main-whyus-box'>
        <p>Remedies Without Demolition</p>
    </div>
    <div className='main-whyus-box'>
        <p>Practical Approach</p>
    </div>
</div>

<div className='main-title'><div className='title'>Know What Your Zodiac Sign Says About You</div></div>
            <div className='main-title-down-image'><img src='\images\title-border.webp' alt='Not Found'/></div>
            <div class="gallery-container">
        <div class="gallery">
            <img src="\images\Zodiac Sign\AQUARI-1.webp" alt="Image 1" className="gallery-image"/>
            <img src="\images\Zodiac Sign\ARIES.webp" alt="Image 2" className="gallery-image"/>
            <img src="\images\Zodiac Sign\CANCER-1.webp" alt="Image 3" className="gallery-image"/>
            <img src="\images\Zodiac Sign\CAPRI-1.webp" alt="Image 4" className="gallery-image"/>
            <img src="\images\Zodiac Sign\GEMINI.webp" alt="Image 5" className="gallery-image"/>
            <img src="\images\Zodiac Sign\LEO-1.webp" alt="Image 6" className="gallery-image"/>
            <img src="\images\Zodiac Sign\LIBRA-1.webp" alt="Image 7" className="gallery-image"/>
            <img src="\images\Zodiac Sign\PISCES-1.webp" alt="Image 8" className="gallery-image"/>
            <img src="\images\Zodiac Sign\SAGITTARIUS.webp" alt="Image 9" className="gallery-image"/>
            <img src="\images\Zodiac Sign\SCORPIO-1.webp" alt="Image 10" className="gallery-image"/>
            <img src="\images\Zodiac Sign\TAURUS-1.webp" alt="Image 11" className="gallery-image"/>
            <img src="\images\Zodiac Sign\VIRGO-1.webp" alt="Image 12" className="gallery-image"/>
        </div>
    </div>

    <div class="grid-container">
  <div className="item1">
    <p className='grid-title'>Vastu Tips</p>
    <p className='grid-content'>A home is a place that gives you a feeling of security and peace. You must have seen people posting pictures of their home and captioning it as their happy place. This very feeling of happiness comes from the comfort they feel in that place.</p>
    </div>
  <div className="item2">
    <p className='grid-title'>your number</p>
    <p className='grid-content'>A home is a place that gives you a feeling of security and peace. You must have seen people posting pictures of their home and captioning it as their happy place. This very feeling of happiness comes from the comfort they feel in that place.</p>
  </div>
  <div className="item3">
    <p className='grid-title'>Tarot Card Reading</p>
    <p className='grid-content'>A home is a place that gives you a feeling of security and peace. You must have seen people posting pictures of their home and captioning it as their happy place. This very feeling of happiness comes from the comfort they feel in that place.</p>
  </div>  
  <div className="item4">
    <p className='grid-title'>Astrology</p>
    <p className='grid-content'>A home is a place that gives you a feeling of security and peace. You must have seen people posting pictures of their home and captioning it as their happy place. This very feeling of happiness comes from the comfort they feel in that place.</p>
  </div>
            </div>

            <div className='main-chek-btn-home'>
<p>Find Your Numberology Number :-</p>
<a href='/check'>Find Now</a>
            </div>

            </div>
      )}
    </div> 

        </Layout>
    )
}

export default Home;
