import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoArrowUpSharp } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";
import React, { useEffect, useState } from "react";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const phoneNumber = "9711043903"; // Aapka WhatsApp number yahan daalein
  const message = "Hello! I would like to connect with you."; // Default message

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const [activeIndex, setActiveIndex] = useState(0); // First FAQ is active by default

  const faqData = [
    {
      question: "1. How can I book a consultation call with Numberology?",
      answer:
        "You can book a consultation call with Numberology by visiting our “Consultation Call” page on the website. There, you’ll find information on the available consultation options and how to schedule your appointment. ",
    },
    {
      question: "2. What is numerology?",
      answer:
        "Numerology is the study of numbers and their mystical significance in our lives. Each number is believed to carry specific vibrations and meanings that can influence our personality, relationships, and life path.",
    },
    {
      question: "3. How does numerology work?",
      answer:
        "Numerology works by analyzing the numerical values associated with letters in your name and your birthdate. These values are then used to calculate key numbers, such as your Life Path Number, Destiny Number, and Soul Urge Number, each offering insights into different aspects of your life.",
    },
    {
      question: "4. What is a Life Path Number, and how do I calculate mine?",
      answer:
        "Your Life Path Number reveals your core personality traits and life purpose. To calculate it, add together the digits of your birthdate until you get a single-digit number (or one of the master numbers: 11, 22, 33). For example, if your birthdate is July 14, 1990: 7 + 1 + 4 + 1 + 9 + 9 + 0 = 31 → 3 + 1 = 4. Your Life Path Number is 4.",
    },
    {
      question: "5. What are master numbers in numerology?",
      answer:
        "Master numbers are 11, 22, and 33. They are considered highly spiritual and powerful numbers with amplified energy. For example, 11 is associated with intuition and spiritual insight, 22 with building and manifesting dreams, and 33 with compassion and spiritual guidance.",
    },
    {
      question: "6. Can numerology predict my future?",
      answer:
        "Numerology doesn’t predict specific future events but provides guidance and insights based on the energy of numbers. By understanding your personal numerology, you can align with your strengths and navigate challenges more effectively.",
    },
    {
      question: "7. What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and Razorpay.",
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="main-title">
        <div className="title">FAQ's</div>
      </div>
      <div className="main-title-down-image">
        <img src="\images\title-border.webp" alt="Not Found" />
      </div>

      <div className="faq-container">
        {faqData.map((item, index) => (
          <div className="faq-item" key={index}>
            <h2 className="faq-question" onClick={() => toggleAnswer(index)}>
              {item.question}
            </h2>
            {activeIndex === index && <p className="faq-answer">{item.answer}</p>}
          </div>
        ))}
      </div>

      <div className="main-footer-container">
      <div className='main-footer-logo-box'>
                <img src='\images\Numberology-logo-bg-remove.png' alt='Not Found'/>
                <p>Mr. Sandeeip arya is known for her commitment to authenticity and continuous learning, providing invaluable guidance for holistic well-being. Her practice is a welcoming and inclusive space for all seekers of wisdom.</p>
            </div>

            <div className='main-footer-uselinks-box'>
                <b className='footer-uselink-title'>Useful Links</b>

                <a href='/'>Home</a>
                <a href='/check'>Check your</a>
                <a href='/service'>Services</a>
                <a href='/about'>About us</a>
                <a href='/contact'>Contact us</a>
            </div>

            <div className='main-footer-contact-box'>
            <b className='footer-uselink-title'>Contact Us</b>

              <p>Phone No - +91 9711043903</p>
              <p>Email -  sandeeiparya@gmail.com</p>
              <p>Mahaveer bhawan karampura M3 New delhi 110015</p>

              <div className='main-footer-icon-box'>
              <p className='footer-uselink-title2'>Follow us : </p>
              <a href='#'><FaFacebook /></a>
            <a href='#'><AiFillInstagram /></a>
            <a href='#'><FaTwitter /></a>
              </div>
            </div>
      </div>

      <div className="main-all-R-reserved">Numberology©. All Rights Reserved 2024 - 2025.</div>

      <div className="booking-section-btn">
        <a href="/contact">BOOK YOUR CALL NOW</a>
      </div>

      {isVisible && (
        <button onClick={scrollToTop} className="scrollToTopBtn">
          <IoArrowUpSharp />
        </button>
      )}

      <a href={whatsappUrl} className="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp />
      </a>
    </>
  );
};

export default Footer;
