import React from 'react'
import Layout from '../components/Layout/Layout'

const Contact = () => {
    return (
        <Layout>

            <div className='main-banner-contact-page'>
                <p className='main-contact-title'>Talk to Numberology for Call Consultation!</p>
                <div className='contact-hP12-box'>
                   <p>1.2 lakh+ Happy Customers</p>
                   <p>100% Accurate Predictions</p>
                </div>
            </div>




            <div className='main-contact-div'>
                <form action="https://formspree.io/f/xvgpadzb" method="POST" className='main-contact-form'>
                    <p className='contact-form-title223'>Consultation Form</p>
                    <input name="name" id="name" type="text" placeholder='Enter Your Full Name' required />
                    <input name="DOB" id="Dob" type="date" placeholder='Enter Your Full Name' required />
                    <input name="phone" id="phone" type="number" placeholder='Enter Your phone Number' required />
                    <input name="Email" id="email" type="email" placeholder='Enter Your Email' required />
                    <textarea name="Message" id="text" type="text" placeholder='Enter Your Message' required></textarea>

                    <section class="date-time-section">
    <h2 className='contact-scdat-title'>Select Consultation Date and Time</h2>

    <input type="number" id="dayInput" name="Date" min="1" placeholder='Enter Date' max="31" required/>

    <input type="number" id="monthInput" name="Month" min="1" placeholder='Enter Month' max="12" required/>

    <input type="number" id="yearInput" name="Year" min="1900" max="2100" placeholder='Enter year' required/>

    <label for="timeInput">Choose a Time:</label>
    <input type="time" id="timeInput" name="Time" required/>
</section>

<section class="radio-section">
    <div class="radio-option">
        <input type="radio" id="option1" name="Booking Status" value="Audio consultation"/>
        <label for="option1">Book your Audio consultation</label>
        </div>
        
        <div class="radio-option">
        <input type="radio" id="option2" name="Booking Status" value="Video consultation"/>
        <label for="option2">Book your Video consultation</label>
        </div>

        <div class="radio-option">
        <input type="radio" id="option3" name="Booking Status" value="Only Report"/>
        <label for="option3">Only Report</label>
        </div>
</section>
                    <button type="submit">Submit</button>
                </form>

                <div className='main-contact-rignt-info '>
                    <div className='contact-info-title contact-form-title223'>CONTACT INFORMATION</div>
                    <div className='main-contact-options'>
                        <p><b>Address :-</b>Moti nagar Karampura Mahaveer Bhawan First Floor M3 New Delhi 110015</p>
                        <p><b>Phone No :-</b>+91 9711043903</p>
                        <p><b>Email :-</b> sandeeiparya@gmail.com</p>
                    </div>
                    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4163.34107705992!2d77.14850711657911!3d28.66155745047328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02ff8489aba7%3A0xd41e6bb5c306beae!2sMoti%20Nagar%2C%20House%20Complex%20Market%2C%20Karampura%20Industrial%20Area%2C%20Karam%20Pura%2C%20Delhi%2C%20110015!5e0!3m2!1sen!2sin!4v1729835658971!5m2!1sen!2sin"
      width="580"
      height="650"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
                </div>
            </div>
        </Layout>

    )
}

export default Contact
