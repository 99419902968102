import { Routes, Route ,Router} from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Check from './pages/Check'
import Services from './pages/Services';
import Dashboard9893 from './pages/Dashboard9893';
import RazorpayPayment from './components/RazorpayPayment';
import SuccessPage from './components/SuccessPage';
import Dashboard9890 from './pages/Dashboard9890';
import ScrollToTop from './pages/ScrollToTop';


const App = () => {
  return (
    <>
    
     <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/check' element={<Check />}></Route>
        <Route path='/service' element={<Services />}></Route>
        <Route path='/dashboard9893' element={<Dashboard9893 />}></Route>
        <Route path='/dashboard9890' element={<Dashboard9890 />}></Route>
        <Route path='/razorpaypayment' element={<RazorpayPayment />}></Route>
        <Route path='/successpage' element={<SuccessPage />}></Route>
      </Routes>
     
    </>

  )
}

export default App;
