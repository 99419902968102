import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { HiPrinter } from "react-icons/hi2";



const CharacterData = {
    'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'V': 6, 'Z': 7, 'F': 8,
    'I': 1, 'K': 2, 'L': 3, 'G': 4, 'H': 5, 'U': 6, 'O': 7, 'P': 8,
    'J': 1, 'R': 2, 'S': 3, 'T': 4, 'N': 5, 'W': 6,
    'Q': 1, 'M': 4, 'X': 5,
    'Y': 1,
};

const determinePlanet = (total) => {
    const planetMappings = {
        //Sun
        1: 'Sun',
        10: 'Sun',
        19: 'Sun',
        28: 'Sun',
        37: 'Sun',
        46: 'Sun',
        55: 'Sun',
        64: 'Sun',
        73: 'Sun',
        82: 'Sun',
        91: 'Sun',
        100: 'Sun',
        109: 'Sun',
        118: 'Sun',
        127: 'Sun',
        136: 'Sun',
        145: 'Sun',
        154: 'Sun',
        163: 'Sun',
        172: 'Sun',
        181: 'Sun',
        190: 'Sun',

        //Moon
        2: 'Moon',
        11: 'Moon',
        20: 'Moon',
        29: 'Moon',
        38: 'Moon',
        47: 'Moon',
        56: 'Moon',
        65: 'Moon',
        74: 'Moon',
        83: 'Moon',
        92: 'Moon',
        101: 'Moon',
        110: 'Moon',
        119: 'Moon',
        128: 'Moon',
        137: 'Moon',
        146: 'Moon',
        155: 'Moon',
        164: 'Moon',
        173: 'Moon',
        182: 'Moon',
        191: 'Moon',

        //Jupiter
        3: 'Jupiter',
        12: 'Jupiter',
        21: 'Jupiter',
        30: 'Jupiter',
        39: 'Jupiter',
        48: 'Jupiter',
        57: 'Jupiter',
        66: 'Jupiter',
        75: 'Jupiter',
        84: 'Jupiter',
        93: 'Jupiter',
        102: 'Jupiter',
        111: 'Jupiter',
        120: 'Jupiter',
        129: 'Jupiter',
        138: 'Jupiter',
        147: 'Jupiter',
        156: 'Jupiter',
        165: 'Jupiter',
        174: 'Jupiter',
        183: 'Jupiter',
        192: 'Jupiter',

        //Rahu
        4: 'Rahu',
        13: 'Rahu',
        22: 'Rahu',
        31: 'Rahu',
        40: 'Rahu',
        49: 'Rahu',
        58: 'Rahu',
        67: 'Rahu',
        76: 'Rahu',
        85: 'Rahu',
        94: 'Rahu',
        103: 'Rahu',
        112: 'Rahu',
        121: 'Rahu',
        130: 'Rahu',
        140: 'Rahu',
        148: 'Rahu',
        157: 'Rahu',
        166: 'Rahu',
        175: 'Rahu',
        184: 'Rahu',
        193: 'Rahu',

        //Mercury
        5: 'Mercury',
        14: 'Mercury',
        23: 'Mercury',
        32: 'Mercury',
        41: 'Mercury',
        50: 'Mercury',
        59: 'Mercury',
        68: 'Mercury',
        77: 'Mercury',
        86: 'Mercury',
        95: 'Mercury',
        104: 'Mercury',
        113: 'Mercury',
        122: 'Mercury',
        131: 'Mercury',
        141: 'Mercury',
        149: 'Mercury',
        158: 'Mercury',
        167: 'Mercury',
        176: 'Mercury',
        185: 'Mercury',
        194: 'Mercury',

        //Venus
        6: 'Venus',
        15: 'Venus',
        24: 'Venus',
        33: 'Venus',
        42: 'Venus',
        51: 'Venus',
        60: 'Venus',
        69: 'Venus',
        78: 'Venus',
        87: 'Venus',
        96: 'Venus',
        105: 'Venus',
        114: 'Venus',
        123: 'Venus',
        132: 'Venus',
        142: 'Venus',
        150: 'Venus',
        159: 'Venus',
        168: 'Venus',
        177: 'Venus',
        186: 'Venus',
        195: 'Venus',

        //Ketu
        7: 'Ketu',
        16: 'Ketu',
        25: 'Ketu',
        34: 'Ketu',
        43: 'Ketu',
        52: 'Ketu',
        61: 'Ketu',
        70: 'Ketu',
        79: 'Ketu',
        88: 'Ketu',
        97: 'Ketu',
        106: 'Ketu',
        115: 'Ketu',
        124: 'Ketu',
        133: 'Ketu',
        143: 'Ketu',
        151: 'Ketu',
        160: 'Ketu',
        169: 'Ketu',
        178: 'Ketu',
        187: 'Ketu',
        196: 'Ketu',

        //Saturn
        8: 'Saturn',
        17: 'Saturn',
        26: 'Saturn',
        35: 'Saturn',
        44: 'Saturn',
        53: 'Saturn',
        62: 'Saturn',
        71: 'Saturn',
        80: 'Saturn',
        89: 'Saturn',
        98: 'Saturn',
        107: 'Saturn',
        116: 'Saturn',
        125: 'Saturn',
        134: 'Saturn',
        144: 'Saturn',
        152: 'Saturn',
        161: 'Saturn',
        170: 'Saturn',
        179: 'Saturn',
        188: 'Saturn',
        197: 'Saturn',

        //Mars
        9: 'Mars',
        18: 'Mars',
        27: 'Mars',
        36: 'Mars',
        45: 'Mars',
        54: 'Mars',
        63: 'Mars',
        72: 'Mars',
        81: 'Mars',
        90: 'Mars',
        99: 'Mars',
        108: 'Mars',
        117: 'Mars',
        126: 'Mars',
        135: 'Mars',
        145: 'Mars',
        153: 'Mars',
        162: 'Mars',
        171: 'Mars',
        180: 'Mars',
        189: 'Mars',
        198: 'Mars',


    };


    return planetMappings[total] || 'Unknown Planet';
};

function createBoxes() {
    const container = document.getElementById('container');
    container.innerHTML = ''; // Clear the container

    const planet = document.getElementById('planet').value;

    // Define numbers for different planets
    const planetNumbers = {
        'Sun': [6, 1, 8, 7, 5, 3, 2, 9, 4],
        'Mars': [8, 3, 10, 9, 7, 5, 4, 11, 6],
        'Ketu': [14, 6, 16, 15, 13, 11, 10, 17, 12],
        'Moon': [7, 2, 9, 8, 6, 4, 3, 10, 5],
        'Jupiter': [10, 5, 12, 11, 9, 7, 6, 13, 8],
        'Satern': [12, 7, 14, 13, 11, 9, 8, 15, 10],
        'Mercury': [9, 4, 11, 10, 8, 6, 5, 12, 7],
        'Venus': [11, 6, 13, 12, 10, 8, 7, 14, 9],
        'Rahu': [13, 8, 15, 14, 12, 10, 6, 16, 11],
        // Add other planets and their corresponding number sequences
    };

    const numbers = planetNumbers[planet];

    // Create and display boxes with the planet's number sequence
    for (let i = 0; i < numbers.length; i++) {
        const box = document.createElement('div');
        box.className = 'box';
        box.textContent = numbers[i];
        container.appendChild(box);
    }

    displayPlanetMessageWeakperiods(planet);
    displayPlanetMessagestrongperiod(planet);
    displayPlanetMessageGoodDates(planet);
    displayPlanetMessageGoodDays(planet);
    displayPlanetMessageFcolors(planet);
    displayPlanetMessageStones(planet);
    displayPlanetMessageMeditation(planet);
    displayPlanetMessageDeity(planet);
    displayPlanetMessageMantra(planet);
    displayPlanetMessageHealtandDiseases(planet);
    displayPlanetMessageFasting(planet);
    displayPlanetMessageFriendship(planet);
    displayPlanetMessageRomance(planet);
    displayPlanetMessageGoodYears(planet);
    displayPlanetMessageSpecialConsideration(planet);
    displayPlanetMessageAstrologicalinsights(planet);
    displayPlanetMessageYourGoodDates(planet);
    displayPlanetMessageYourGoodDays(planet);
    displayPlanetMessageYourFevColors(planet);
    displayPlanetMessageYourFevstone(planet);
    displayPlanetMessageYourStrongPeriods(planet);
    displayPlanetMessageYourWeakPeriods(planet);
    displayPlanetMessageYourMeditation(planet);
    displayPlanetMessageYourMantra(planet);
    displayPlanetMessageYourHealthandDiseases(planet);
    displayPlanetMessageYourFriendShips(planet);
    displayPlanetMessageYourGoodYearofLife(planet);
    displayPlanetMessageYourGod(planet);
    displayPlanetMessageYourYantra(planet);
    displayPlanetMessageYourMetal(planet);
    displayPlanetMessageYourBenificAge(planet);
    displayPlanetMessageNumberologyPredicitionlast(planet);
}

function displayPlanetMessageWeakperiods(planet) {
    const messageContainer = document.getElementById('message1');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'These are the periods when the sun is weak: October,November and December. During these times, their effciency could be retarded due to which they can suffer financial losses,have unnecessary worries, be blamed for errors and earn a bad reputation. New steps like building a new venture,starting new job and investing is not recommended during this period. Nuumber 1 men should refrain from connecting or conversing with the opposite sex during these months.';
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessagestrongperiod(planet) {
    const messageContainer = document.getElementById('message2');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Strong periods for number ones are from March 21 to April 28 and from July 10 to AUgust 20 which are best suited for making new contracts and starting new jobs.';
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageGoodDates(planet) {
    const messageContainer = document.getElementById('message3');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Date 1,4,10,13,19,22,28 and 31 of any month which are good for them. But 1,19 and 28 are espicially the most favourable ones. All jobs commenced from these dates see the light of success without fail.If these dates fall within one of their strong periods, they could become the turning point of their lives.';
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageGoodDays(planet) {
    const messageContainer = document.getElementById('message4');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Sunday and Monday are considered as the good days. If the same falls on the date 19 or 28of any month, it is concidered to be more fruitful.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageFcolors(planet) {
    const messageContainer = document.getElementById('message5');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'When talking about colors; orange,yellow,golden yellow,copper and gold are best suited for them.It is recommended thet they should keep these colors around their living zone and workplace as curtains,pillow,sheets,covers etc . In the hours of stress and unfavourable periods, a handkerchief of any of these colors can help greatly.Even just a glance at these colors welcomes the arrival of good energy.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageStones(planet) {
    const messageContainer = document.getElementById('message6');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'In terms of precious stone; a ruby of 3 carats is considered luck for men. The rubey ring should have an open back settings.it should be worn when the ritual are performed and should be bought on either Sunday or Monday. Women with psychic number 1 can wear red spinel,garnet , or other ruby substitutes. Furtermore the man who are born on the tenth day or in the tenth month or with a zero in their birth date are recommended to wear ruby rings.Women belonging to the same category should wear garnet or other ruby substitute jewellery as rings or pendants. In order to mitigate the misfortune that the number zero bringd,both the sexes should wake up before sunrice and kiss their gemstone and gawk at it with love. Additionally, they shold perform workship or donate money to men belonging to the holy world in order to please the sun. Ruby ring set should be worn by men born on the twenty-eight day, and pearl ring set should be worn by number 1 ladies born. All the people with 1 as their psychic number should utilize ruby powder for electrochmical healing after they trun fifty.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageMeditation(planet) {
    const messageContainer = document.getElementById('message7');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'It is recommended to meditation on the rising sun, if it is not possible, they should meditate on the ruby'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageDeity(planet) {
    const messageContainer = document.getElementById('message8');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'The deity of number 1 people is the sun god . The sun is personified as seated on a pink lotus in lotus posture. He holds the lotus while driving the chariot led by seven white horses and showers blessing and smiles.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageMantra(planet) {
    const messageContainer = document.getElementById('message9');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'It is recommended that repeated recitation of the mantra should be done during the ascending once of the moon and repeated as prescribed. It is said ro recite " AUM HRIM HRIM SURIYAYE NAMAH AUM " mantra 7000 times within the ascencing cycle of the moon.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageHealtandDiseases(planet) {
    const messageContainer = document.getElementById('message10');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Every thought number 1s have strong builds the downside is they also have problems with their circulatory system, which in turn makes them suffer from high blood pressure during their old age. their eyes also cause serious concern. They may be hospitalized often when they turn fifty-six but they recover quickly and live a long life . After they pass fifty; it id recommended for them to consume foods that will purify their blood and they should refrain themselces from hard work. They should massage their body with almond oil in order to maintain good blood circulation.It is recommended to do exercides which enhances circulation, food which cause acidity should not be a part of their diet, They have bile dominated temperament, the bile gets aggravated due to various factors such as grief,fear,improper digestion and use of bitter,acidic and dry substances, They should avoid oily foods , fish,meat and hot eat in the late hours of the evening. They should utilize powderd pearls and powdered rubies before starting new medicine. as they help to keep body chemistry alkaline and provide strenght during their weak periods respectively.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageFasting(planet) {
    const messageContainer = document.getElementById('message11');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'It is recommended to fast all day on sunday and consume salt free fasting food once a day before sunset as it is beneficial for all the the number one people.During summer and autumn, the bile gets aggravated and at that time three days fasting on lemon water for purifying blood helps a lot '
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageFriendship(planet) {
    const messageContainer = document.getElementById('message12');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Psychic number one who are born on day 1,10,19 or 28 of any month, make friends for long term. number one people born between July 10 and August 20 become reliable and trustworthy friends.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageRomance(planet) {
    const messageContainer = document.getElementById('message13');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Number one are naturally attracted to the members of their opposite gender who are born on any date which adds up to either number 1,4 or 7 if number 1s end up coming in a relationship, the bond stay alive for year but after that they need to find partners that keep the excitement and thrill of the relationship going or else the relationship falls apart. Number 1s dont gel well with number 4s though the 4s thought the 4s give 1s energy.if you want to commit to a long-term partnership, avoid number 7s at all costs. Number 1s can from companionship with both 4s and 7s but they prefer 4s people with number 8 as psychic number are to be avoided as marriage partners , but they can prove beneficial for business partnership and affairs.Psychic number 1 women should not get married to men with psychic, destiny or name number of 8. Even the men whose birth year adds up to 8 should be avoided at all costs. Eighth of any month should not be considered as the wedding day. '
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageGoodYears(planet) {
    const messageContainer = document.getElementById('message14');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'You can count on the following years to be come of the good years of for people with psysic number 1 ; 1st year, 10th , 9th , 28th , 37th , 46th , 55th , 64th , 73rd , 82nd , and 91st . '
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageSpecialConsideration(planet) {
    const messageContainer = document.getElementById('message15');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'On a special note, the ones who are born on twenty eighth are adviesed to plan their future carefully in terms of monetary value. They should keep an eye on their spends and should take preventive measures for avoidung loss, either through business or litigation.'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Moon':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Jupiter':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Satern':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Mercury':
            message = 'The Sun is the center of our solar system.';
            break;
        case 'Venus':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Rahu':
            message = 'The Sun is the center of our solar system.';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

// _________________________________________

function displayPlanetMessageAstrologicalinsights(planet) {
    const messageContainer = document.getElementById('message16');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = ' Sun On a special note'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageYourGoodDates(planet) {
    const messageContainer = document.getElementById('message17');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '4,13,22,31'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageYourGoodDays(planet) {
    const messageContainer = document.getElementById('message18');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Sunday , Monday , Saturday'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourFevColors(planet) {
    const messageContainer = document.getElementById('message19');
    let message;

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Gold , Oranged , Yellow'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourFevstone(planet) {
    const messageContainer = document.getElementById('message20');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Gomed , Black Hakik'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourStrongPeriods(planet) {
    const messageContainer = document.getElementById('message21');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '.....'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourWeakPeriods(planet) {
    const messageContainer = document.getElementById('message22');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '.....'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourMeditation(planet) {
    const messageContainer = document.getElementById('message23');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '.....'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourMantra(planet) {
    const messageContainer = document.getElementById('message24');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '.....'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourHealthandDiseases(planet) {
    const messageContainer = document.getElementById('message25');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '.....'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
};

function displayPlanetMessageYourFriendShips(planet) {
    const messageContainer = document.getElementById('message26');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '.....'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageYourGoodYearofLife(planet) {
    const messageContainer = document.getElementById('message27');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '1975, 1984, 1993, 2002, 2011, 2020, 2029, 2038'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourGod(planet) {
    const messageContainer = document.getElementById('message28');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Ganesh'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageYourYantra(planet) {
    const messageContainer = document.getElementById('message29');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Sun Yantra'
            break;
        case 'Mars':
            message = 'Mars Yantra';
            break;
        case 'Ketu':
            message = 'Ketu Yantra';
            break;
        case 'Moon':
            message = 'Moon Yantra';
            break;
        case 'Jupiter':
            message = 'Jupiter Yantra';
            break;
        case 'Satern':
            message = 'Satern Yantra';
            break;
        case 'Mercury':
            message = 'Mercury Yantra';
            break;
        case 'Venus':
            message = 'Venus Yantra';
            break;
        case 'Rahu':
            message = 'Rahu Yantra';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageYourMetal(planet) {
    const messageContainer = document.getElementById('message30');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'lead'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function displayPlanetMessageYourBenificAge(planet) {
    const messageContainer = document.getElementById('message31');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = '10, 19, 28, 37, 46, 55, 64, 73'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}

function displayPlanetMessageNumberologyPredicitionlast(planet) {
    const messageContainer = document.getElementById('message32');
    let message

    // Define messages for each planet
    switch (planet) {
        case 'Sun':
            message = 'Data Not Fill'
            break;
        case 'Mars':
            message = 'Mars is often called the Red Planet.';
            break;
        case 'Ketu':
            message = 'Ketu';
            break;
        case 'Moon':
            message = 'Moon';
            break;
        case 'Jupiter':
            message = 'Jupiter';
            break;
        case 'Satern':
            message = 'Satern';
            break;
        case 'Mercury':
            message = 'Mercury';
            break;
        case 'Venus':
            message = 'Venus';
            break;
        case 'Rahu':
            message = 'Rahu';
            break;
        // Add cases for other planets

        default:
            message = 'Select a planet to see a related message.';
    }
    // Display the message
    messageContainer.textContent = message;
}


function updateGraph(inputIndex, value) {
    const maxHeightPercentage = 100;
    const heightPercentage = Math.min(maxHeightPercentage, Math.max(0, value));

    const graphBar = document.querySelectorAll('.graph-container .graph-bar')[inputIndex];
    graphBar.style.height = `${heightPercentage}%`;

    // Add the value as text inside the graph bar
    const graphBarValue = document.createElement('div');
    graphBarValue.className = 'graph-bar-value';
    graphBarValue.textContent = value;
    graphBar.appendChild(graphBarValue);
}

function addGraph() {
    const inputNumber = document.getElementById('input-field');
    const values = inputNumber.value.split(' ').map(parseFloat);

    const graphContainer = document.getElementById('graph-container');

    values.forEach((value, index) => {
        const newGraphBar = document.createElement('div');
        newGraphBar.className = 'graph-bar';
        graphContainer.appendChild(newGraphBar);

        updateGraph(index, value);
    });
}


const NameAnalyzer = () => {
    const [name, setName] = useState('');
    const [totalValue, setTotalValue] = useState(null);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [dobTotal, setDobTotal] = useState(null);
    const [dateMonthYearTotal, setDateMonthYearTotal] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [gender] = useState('male'); // Default to 'male'
    const [selectedImage, setSelectedImage] = useState('');


    const handleInputChange = (event) => {
        const inputName = event.target.value.toUpperCase();
        let total = 0;

        for (let i = 0; i < inputName.length; i++) {
            const char = inputName[i];
            if (CharacterData[char]) {
                total += CharacterData[char];
            }
        }
        setName(inputName);
        setName(inputName);
        setTotalValue(total);
    };

    const handleDayChange = (event) => {
        setDay(event.target.value);
        calculateDobTotal();
        calculateDateMonthYearTotal();
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        calculateDobTotal();
        calculateDateMonthYearTotal();
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        calculateDobTotal();
        calculateDateMonthYearTotal();
    };

    const calculateDobTotal = () => {
        // Assuming the date of birth inputs are valid integers
        const dayTotal = day.split('').reduce((acc, digit) => acc + parseInt(digit), 0);
        setDobTotal(dayTotal);
    };

    const calculateDateMonthYearTotal = () => {
        const dayTotal = [...day].reduce((acc, digit) => acc + parseInt(digit), 0);
        const monthTotal = [...month].reduce((acc, digit) => acc + parseInt(digit), 0);
        const yearTotal = [...year].reduce((acc, digit) => acc + parseInt(digit), 0);
        const total = dayTotal + monthTotal + yearTotal;

        setDateMonthYearTotal(total);
    };

    const getAstrologicalPrediction = (planet) => {
        switch (planet) {
            case 'Sun':
                return 'Litigation, loss of money, and challenges are foreseen. Consider consulting an astrologer for remedies.';
            case 'Moon':
                return 'Emotional ups and downs may be expected. A change of name could bring positive transformations.';
            // Add more cases for other planets as needed
            default:
                return 'Astrological insights for this planet are not available.';
        }
    };


    const handleCapture = () => {
        const captureDiv = document.getElementById('captureDiv');

        if (captureDiv) {
            html2canvas(captureDiv).then((canvas) => {
                // Convert the canvas to a data URL
                const imageData = canvas.toDataURL('image/png');

                // Create a link element and set its attributes
                const downloadLink = document.createElement('a');
                downloadLink.href = imageData;
                downloadLink.download = 'captured_image.png';

                // Append the link to the body and trigger a click event
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Remove the link from the body
                document.body.removeChild(downloadLink);
            });
        }
    };


    const handleCapture2 = () => {
        const captureDiv2 = document.getElementById('captureDiv2');

        if (captureDiv2) {
            html2canvas(captureDiv2).then((canvas) => {
                // Convert the canvas to a data URL
                const imageData2 = canvas.toDataURL('image/png');

                // Create a link element and set its attributes
                const downloadLink2 = document.createElement('a');
                downloadLink2.href = imageData2;
                downloadLink2.download = 'captured_image.png';

                // Append the link to the body and trigger a click event
                document.body.appendChild(downloadLink2);
                downloadLink2.click();

                // Remove the link from the body
                document.body.removeChild(downloadLink2);
            });
        }
    };


    const handleCapture3 = () => {
        const captureDiv3 = document.getElementById('captureDiv3');

        if (captureDiv3) {
            html2canvas(captureDiv3).then((canvas) => {
                // Convert the canvas to a data URL
                const imageData3 = canvas.toDataURL('image/png');

                // Create a link element and set its attributes
                const downloadLink3 = document.createElement('a');
                downloadLink3.href = imageData3;
                downloadLink3.download = 'captured_image.png';

                // Append the link to the body and trigger a click event
                document.body.appendChild(downloadLink3);
                downloadLink3.click();

                // Remove the link from the body
                document.body.removeChild(downloadLink3);
            });
        }
    };

    const handleCapture4 = () => {
        const captureDiv4 = document.getElementById('captureDiv4');

        if (captureDiv4) {
            html2canvas(captureDiv4).then((canvas) => {
                // Convert the canvas to a data URL
                const imageData4 = canvas.toDataURL('image/png');

                // Create a link element and set its attributes
                const downloadLink4 = document.createElement('a');
                downloadLink4.href = imageData4;
                downloadLink4.download = 'captured_image.png';

                // Append the link to the body and trigger a click event
                document.body.appendChild(downloadLink4);
                downloadLink4.click();

                // Remove the link from the body
                document.body.removeChild(downloadLink4);
            });
        }
    };


    const handlePrint = () => {
        window.print();
    };


    useEffect(() => {
        // Save to local storage when name, gender, or related information changes
        localStorage.setItem('names', JSON.stringify(getStoredNames()));
        localStorage.setItem('gender', gender);
        // ... (you can add more items to store in local storage)
    }, [name, gender, day, month, year, dobTotal, dateMonthYearTotal]);

    const getStoredNames = () => {
        const storedNames = JSON.parse(localStorage.getItem('names')) || [];
        return [...new Set(storedNames)]; // Remove duplicates using Set
    };

    const saveName = () => {
        if (name.trim() !== '') {
            const storedNames = getStoredNames();
            storedNames.push(name);
            localStorage.setItem('names', JSON.stringify(storedNames));
        }
    };



    const handleSearchChange = (event) => {
        const query = event.target.value.toUpperCase();
        setSearchQuery(query);
        const storedNames = getStoredNames();
        const filteredSuggestions = storedNames.filter((storedName) =>
            storedName.toUpperCase().includes(query)
        );
        setSuggestions(filteredSuggestions);
    };

    const handleImageChange = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = function (e) {
                setSelectedImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };


    return (
        <>
            <button className='dasboard-print' onClick={handlePrint}><HiPrinter />
            </button>
            {saveName}
            <h1 className='admin-dashboard'>ADMIN DASHBOARD</h1>

            <div className='main-check-desh'>
                <div className='main-check-name NR'>
                    <div className='check-info' style={{ textAlign: 'justify' }}>Whether for families or for corporate clients, Sri. V.S. Guruswamy offers his consultation only in person for specific reasons.
                        This was the policy of his father and guru, Pandit Sethuraman from day one which continues to be followed by his son also. Consultations are being done only
                        by fixing prior appointments by mail. We do not have any representatives or branches anywhere in this world except our official residence.
                        Readers are requested to note that our office functions only at the following address:<br />
                    </div>
                </div>
            </div>
            <div className='main-check-desh2'>
                <div className='main-check-name'>
                    <div className='check-yours-title'>Check Your</div>
                    <div className='check-title-name'>Name Numerology</div>

                    <div className='check-info'>This is an interesting section! Type in your name in the box provided below and we
                        will tell you your name number and interesting facts about your name number</div>

                    <label>
                        <input type="text" value={name} onChange={handleInputChange} placeholder=' Enter your name' className='input-name' />
                        <p className='name-total-input'> {totalValue}</p>
                    </label>

                    <div className='main-des-search-feild'>
                        <label><input mtype="text" value={searchQuery} onChange={handleSearchChange} placeholder='Search names' className='input-name-search' /></label>

                        <div className='box-searching-name'>
                            {suggestions.map((suggestName, index) => (
                                <li className='searching-name' key={index}>{suggestName}</li>
                            ))}
                        </div>


                    </div>
                        <input type="file" onChange={handleImageChange} accept="image/*" />
                </div>



                <div className='main-check-name'>
                    <div className='check-title-name'>Birth Number Numerology  </div>
                    <div className='check-info'>Another section that will classify your birth number and give you interesting information about the number</div>


                    <div >
                        <div className='check-info'>Enter Your Date of Birth</div>
                        <label>
                            <input type="text" placeholder="Day" className='input-DOB' value={day} onChange={handleDayChange} onBlur={calculateDateMonthYearTotal} />
                        </label>
                        <label>
                            <input type="text" placeholder="Month" className='input-DOB' value={month} onChange={handleMonthChange} onBlur={calculateDateMonthYearTotal} />
                        </label>
                        <label>
                            <input type="text" placeholder="Year" className='input-DOB' value={year} onChange={handleYearChange} onBlur={calculateDateMonthYearTotal} />
                        </label>
                    </div>
                </div>
                <div id="captureDiv">
                    <div className='main-check-name NR'>
                        {totalValue !== null && (
                            <div>
                                <p className='main-names-page mmdtextname' ><div><h3 className='main-big-title mmdaswebnam'><img src='\images\NUMBEROLOGY-header-logo.png' alt=' Not Found'/></h3>
                                {name}<p className='main-names-page mmddobdmy'>Date of birth- {day}/{month}/{year}</p></div>                                  

                                    <div>
                                        {selectedImage && (
                                            <img src={selectedImage} className='mmdasmimg' alt="Selected Image"/>
                                        )}
                                    </div>
                                </p>

                                <svg
      id="wave"
      style={{ transform: 'rotate(180deg)', transition: '0.3s' }}
      viewBox="0 0 1440 130"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stopColor="#99155e" offset="100%" />
        </linearGradient>
      </defs>
      <path
        style={{ transform: 'translate(0, 0px)', opacity: 1 }}
        fill="url(#sw-gradient-0)"
        d="M0,26L80,36.8C160,48,320,69,480,71.5C640,74,800,56,960,49.8C1120,43,1280,48,1440,54.2C1600,61,1760,69,1920,65C2080,61,2240,43,2400,39C2560,35,2720,43,2880,41.2C3040,39,3200,26,3360,30.3C3520,35,3680,56,3840,71.5C4000,87,4160,95,4320,86.7C4480,78,4640,52,4800,54.2C4960,56,5120,87,5280,91C5440,95,5600,74,5760,62.8C5920,52,6080,52,6240,62.8C6400,74,6560,95,6720,91C6880,87,7040,56,7200,49.8C7360,43,7520,61,7680,60.7C7840,61,8000,43,8160,34.7C8320,26,8480,26,8640,30.3C8800,35,8960,43,9120,54.2C9280,65,9440,78,9600,71.5C9760,65,9920,39,10080,39C10240,39,10400,65,10560,65C10720,65,10880,39,11040,39C11200,39,11360,65,11440,78L11520,91L11520,130L11440,130C11360,130,11200,130,11040,130C10880,130,10720,130,10560,130C10400,130,10240,130,10080,130C9920,130,9760,130,9600,130C9440,130,9280,130,9120,130C8960,130,8800,130,8640,130C8480,130,8320,130,8160,130C8000,130,7840,130,7680,130C7520,130,7360,130,7200,130C7040,130,6880,130,6720,130C6560,130,6400,130,6240,130C6080,130,5920,130,5760,130C5600,130,5440,130,5280,130C5120,130,4960,130,4800,130C4640,130,4480,130,4320,130C4160,130,4000,130,3840,130C3680,130,3520,130,3360,130C3200,130,3040,130,2880,130C2720,130,2560,130,2400,130C2240,130,2080,130,1920,130C1760,130,1600,130,1440,130C1280,130,1120,130,960,130C800,130,640,130,480,130C320,130,160,130,80,130L0,130Z"
      />
    </svg>


                                {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <p className='main-names-page'>Name- {name}</p>
                                    <p className='main-names-page'>Date of birth- {day}/{month}/{year}</p>
                                </div> */}
                                <p><b>1.</b> Total character value of your name: <b className='final-values'>{totalValue}</b></p>
                                <p><b>2.</b> Corresponding planet: <b className='final-values'>{determinePlanet(totalValue)}</b></p>


                                <p className='higlite-contant'>The interpretations for your name number are:</p>
                                <p><b>3.</b> Astrological insights: <b className='final-values'>{getAstrologicalPrediction(determinePlanet(totalValue))}</b></p>
                            </div>
                        )}

                        {dateMonthYearTotal !== null && (
                            <div>
                                <p><b>4.</b> Your Birth Number and Destiny Number: <b className='final-values'>{dateMonthYearTotal}</b></p>
                                {/* Add any additional functionality or display related to date, month, and year total */}
                            </div>
                        )}
                        {dobTotal !== null && (
                            <div>
                                <p><b>5.</b> Your Birth Number and Psychic Number: <b className='final-values'>{dobTotal}</b></p>
                                <p><b>6.</b> Astrological insights based on date of birth:<b className='final-values' id="message16"></b></p>
                                <p><b>7.</b> Your Good Dates:<b className='final-values' id="message17"></b></p>
                                <p><b>8.</b> Your Good Days: <b className='final-values' id="message18"></b></p>
                                <p><b>9.</b> Your Fev Colors: <b className='final-values' id="message19"></b></p>
                                <p><b>10.</b> Your Fev Stone:<b className='final-values' id="message20"></b></p>
                                <p><b>11.</b> Your Strong Periods: <b className='final-values' id="message21"></b></p>
                                <p><b>12.</b> Your Weak Periods:<b className='final-values' id="message22"></b></p>
                                <p><b>13.</b> Your Meditation: <b className='final-values' id="message23"></b></p>
                                <p><b>14.</b> Your Mantra: <b className='final-values' id="message24"></b></p>
                                <p><b>15.</b> Your Health and Diseases:<b className='final-values' id="message25"></b></p>
                                <p><b>16.</b> Your FriendShips : <b className='final-values' id="message26"></b></p>
                                <p><b>17.</b> Your Good Year of Life : <b className='final-values' id="message27"></b></p>
                                <p><b>18.</b> Your God :<b className='final-values' id="message28"></b></p>
                                <p><b>19.</b> Your Yantra : <b className='final-values' id="message29"></b></p>
                                <p><b>20.</b> Your Metal : <b className='final-values' id="message30"></b></p>
                                <p><b>21.</b> Your Benific Age : <b className='final-values' id="message31"></b></p>

                                <div>
                                <h2 className='main-big-title'>YOUR YANTRA</h2> 
                                    <div className="Box">
                                        <select id="planet" onChange={createBoxes}>
                                            <option value="Sun">Sun Yantra</option>
                                            <option value="Mars">Mars Yantra</option>
                                            <option value="Ketu">Ketu Yantra</option>
                                            <option value="Moon">Moon Yantra</option>
                                            <option value="Jupiter">Jupiter Yantra</option>
                                            <option value="Satern">Saturn Yantra</option>
                                            <option value="Mercury">Mercury Yantra</option>
                                            <option value="Venus">Venus Yantra</option>
                                            <option value="Rahu">Rahu Yantra</option>
                                            {/* Add other planet options as needed */}
                                        </select>
                                    </div>
                                    <div className="main-box">
                                        <div className="square" id="container" />
                                    </div>
                                </div>
                                <button id="captureBtn" className="PNG" onClick={handleCapture}>
                                    
                                </button>

                            </div>

                        )}
                    </div>
                </div>

                <div id="captureDiv2">
                    <div className='main-check-name NR'>
                        <div>
                            <p><h2 className='main-big-title'>Numerology Life Graph</h2></p>

                            <p><h4> Benific Age</h4> </p>
                            <div className="graph-container" id="graph-container" />
                            <div className="input-container" id="input-container">
                                <p>Your Benific Age: -</p>
                                <input type="text" id="input-field" placeholder="Enter values separated by space" />
                                <button onClick={addGraph} className='graph-btn'>Make Graph </button>
                            </div>
                        </div>

                        <button id="captureBtn2" className="PNG" onClick={handleCapture2}>
                            
                        </button>
                    </div>
                </div>

                <div id="captureDiv3">
                    <div className='main-check-name NR'>
                        <h2 className='main-big-title'>Numberology Predicition</h2>
                        <p><div id="message32"></div></p>
                        <button id="captureBtn3" className="PNG" onClick={handleCapture3}>
                           
                        </button>
                    </div>

                </div>

                <div id="captureDiv4">
                    <div className='main-check-name NR'>
                        <h2 className='main-big-title'>NUMEROLOGICAL REMEDIES</h2>

                        <p><h4 className='main-big-title'>1. Weak Periods</h4><div id="message1"></div></p>
                        <p><h4 className='main-big-title'>2. Strong Periods</h4><div id="message2"></div></p>
                        <p><h4 className='main-big-title'>3. Good Dates</h4><div id="message3"></div></p>
                        <p><h4 className='main-big-title'>4. Good Days</h4><div id="message4"></div></p>
                        <p><h4 className='main-big-title'>5. Favourable Colors</h4><div id="message5"></div></p>
                        <p><h4 className='main-big-title'>6. Precious Stones</h4><div id="message6"></div></p>
                        <p><h4 className='main-big-title'>7. Meditation</h4><div id="message7"></div></p>
                        <p><h4 className='main-big-title'>8. Deity</h4><div id="message8"></div></p>
                        <p><h4 className='main-big-title'>9. Mantra</h4><div id="message9"></div></p>
                        <p><h4 className='main-big-title'>10. Health and Diseases</h4><div id="message10"></div></p>
                        <p><h4 className='main-big-title'>11. Fasting</h4><div id="message11"></div></p>
                        <p><h4 className='main-big-title'>12. FriendShips</h4><div id="message12"></div></p>
                        <p><h4 className='main-big-title'>13. Romance</h4><div id="message13"></div></p>
                        <p><h4 className='main-big-title'>14. Good Years</h4><div id="message14"></div></p>
                        <p><h4 className='main-big-title'>15. Special Consideration</h4><div id="message15"></div></p>

                        <button id="captureBtn4" className="PNG" onClick={handleCapture4}>
                           
                        </button>
                    </div >
                </div>
                <svg
      id="wave"
      style={{ transform: 'rotate(0deg)', transition: '0.3s' }}
      viewBox="0 0 1440 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stopColor="rgba(255, 123, 0, 1)" offset="0%"></stop>
          <stop stopColor="rgba(255, 123, 0, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style={{ transform: 'translate(0, 0px)', opacity: 1 }}
        fill="url(#sw-gradient-0)"
        d="M0,0L80,15C160,30,320,60,480,65C640,70,800,50,960,46.7C1120,43,1280,57,1440,51.7C1600,47,1760,23,1920,20C2080,17,2240,33,2400,41.7C2560,50,2720,50,2880,55C3040,60,3200,70,3360,61.7C3520,53,3680,27,3840,26.7C4000,27,4160,53,4320,53.3C4480,53,4640,27,4800,13.3C4960,0,5120,0,5280,0C5440,0,5600,0,5760,0C5920,0,6080,0,6240,1.7C6400,3,6560,7,6720,6.7C6880,7,7040,3,7200,8.3C7360,13,7520,27,7680,35C7840,43,8000,47,8160,55C8320,63,8480,77,8640,71.7C8800,67,8960,43,9120,36.7C9280,30,9440,40,9600,45C9760,50,9920,50,10080,51.7C10240,53,10400,57,10560,58.3C10720,60,10880,60,11040,51.7C11200,43,11360,27,11440,18.3L11520,10L11520,100L11440,100C11360,100,11200,100,11040,100C10880,100,10720,100,10560,100C10400,100,10240,100,10080,100C9920,100,9760,100,9600,100C9440,100,9280,100,9120,100C8960,100,8800,100,8640,100C8480,100,8320,100,8160,100C8000,100,7840,100,7680,100C7520,100,7360,100,7200,100C7040,100,6880,100,6720,100C6560,100,6400,100,6240,100C6080,100,5920,100,5760,100C5600,100,5440,100,5280,100C5120,100,4960,100,4800,100C4640,100,4480,100,4320,100C4160,100,4000,100,3840,100C3680,100,3520,100,3360,100C3200,100,3040,100,2880,100C2720,100,2560,100,2400,100C2240,100,2080,100,1920,100C1760,100,1600,100,1440,100C1280,100,1120,100,960,100C800,100,640,100,480,100C320,100,160,100,80,100L0,100Z"
      ></path>
    </svg>
                <div className='dashbord-footer-main-box'>
                    <p className='mmdasfotwebnam'>Numberology.com</p>
                    <p className='mmdasfotadd'>Shivam House Karampura Commerial Complex M3</p>
                    <p className='mmdasfotcontNo'>+91 9711043903</p>
                </div>
            </div>

        </>
    );
};

export default NameAnalyzer;