import React from 'react';
import { NavLink } from 'react-router-dom'
import { FaFacebook, FaTwitter  } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";


const Header = () => {

    return (
        <>
        <div className='main-upper-navbar'>
            <div className='main-contact-upper-section'>
            <p>Phone No - 1234567890</p>
            <p>Email - Example234@gmail.com</p>
            </div>

            <div className='main-social-media-icons-section'>
            <p className='title-follow-on-header'>Follow on - </p>
            <a href='#'><FaFacebook /></a>
            <a href='#'><AiFillInstagram /></a>
            <a href='#'><FaTwitter /></a>
            </div>
        </div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <NavLink to='/' className="navbar-brand" href="#"><img className='logoimage' src='\images\NUMBEROLOGY-header-logo.png' alt='Not Found'/></NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to='/' className="nav-link" aria-current="page" href="#">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/check' className="nav-link " aria-disabled="true">Check your</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/service' className="nav-link " aria-disabled="true">Services</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/about' className="nav-link" href="#">About us</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/contact' className="nav-link" href="#">Contact us</NavLink>
                            </li>
                            <li className="nav-item contact-btn-main">
                            <NavLink to='/contact' className="nav-link " aria-disabled="true">Get Consultation</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header
