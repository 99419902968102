import React, { useState } from 'react';

const DateValueCalculator = () => {
  const [name, setName] = useState('');
  const [totalValue, setTotalValue] = useState(null);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [dobTotal, setDobTotal] = useState(null);
  const [dateMonthYearTotal, setDateMonthYearTotal] = useState(null);
  const [singleDigitTotal, setSingleDigitTotal] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [showNameResults, setShowNameResults] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);  // New loading state

  const [selectedItem, setSelectedItem] = useState('');

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const printPage = () => {
    window.print(); 
  };


  const reduceToSingleDigit = (num) => {
    while (num > 9) {
      num = num.toString().split('').reduce((acc, digit) => acc + parseInt(digit), 0);
    }
    return num;
  };

 
  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleReload = () => {
    window.location.reload();
  };

  const calculateDobTotal = () => {
    const dayTotal = day.split('').reduce((acc, digit) => acc + parseInt(digit), 0);
    setDobTotal(reduceToSingleDigit(dayTotal)); 
  };

  const calculateDateMonthYearTotal = () => {
    const dayTotal = [...day].reduce((acc, digit) => acc + parseInt(digit), 0);
    const monthTotal = [...month].reduce((acc, digit) => acc + parseInt(digit), 0);
    const yearTotal = [...year].reduce((acc, digit) => acc + parseInt(digit), 0);

    let total = dayTotal + monthTotal + yearTotal;
    const reducedTotal = reduceToSingleDigit(total); 

    setDateMonthYearTotal(total);
    setSingleDigitTotal(reducedTotal); 
  };

  const handleSubmit = () => {
    setLoading(true);  

    
    setTimeout(() => {
      calculateDobTotal(); 
      calculateDateMonthYearTotal();

     
      setShowResults(true); 
      setShowNameResults(true); 

      if (dobTotal === 0 && singleDigitTotal === 0) {
        setMessage(
          <div className="container9890">
             <table className="info-table">
             <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
              <tr>
            <td className="label">Please fill Your Date of Birth like : - </td>
            <td className="value"><b className="final-values">01/01/2000</b></td>
            </tr>
            </tbody>
            </table>
          </div>
        );

        // 1 to 1 numberology results
      }else if (dobTotal === 1 && singleDigitTotal === 1) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24, 4, 13, 22, 31</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">1, 19, 37, 46, 64, 6, 15, 24, 33, 42, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fire, Iron and Steel, Electricity, Power</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">1, 19, 37, 46, 64, 6, 15, 24, 33, 42, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Dark Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Yellow Sapphire, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Hyper Tension, Boils</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
      } else if (dobTotal === 1 && singleDigitTotal === 2) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 2, 7</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24, 7, 6, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Exports, Fancy Goods, Fire, Electricals</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Yellow Sapphire, Pearl, Cat's Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Alcoholism, Sexual Disease</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
      }else if (dobTotal === 1 && singleDigitTotal === 3) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 3</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 3, 12, 21, 30, 5, 14, 23, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fire, Rice, Grocery, Mall, Dairy, Poultry, Automobiles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Light Blue, Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Yellow Sapphire, Amethyst</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Skin Disease, Allergy, Knee Pain, Nerves</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 1 && singleDigitTotal === 4) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Sun and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fire, Iron, Electrical Goods, Writing, Politics</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46 , 64, 82, 15, 24, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Golden Sapphire, Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Skin Disease, Neurology Problems, Knee Pain</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 1 && singleDigitTotal === 5) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Sun and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Mercury, and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1, 6, and 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 82, 14, 23, 32, 41, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Commission Agency, Contracts, Steel, Fire, Exports</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 82, 14, 23, 32, 41, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Ruby, Diamond, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Heat, Joints Pain, Sexual Diseases</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 1 && singleDigitTotal === 6) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Sun and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 82, 15, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contracts, Fancy Goods, Jewelry, Steel, Fire</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 82, 15, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Golden Sapphire, Ruby, Emerald</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Hyper Tension, Diabetes, Sexual Diseases</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 1 && singleDigitTotal === 7) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Sun and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Moon, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 1, 2</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 2, 11, 20, 29, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 15, 24, 33, 42</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Metal, Granite, Fire, Minerals</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 15, 24, 33, 42</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Golden Sapphire, Pearl, Cat's Eye, Yellow Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Illness, Sinus, Diabetes, Depression</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 1 && singleDigitTotal === 8) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Sun and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1, 5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 14, 23, 32, 41, 59, 15, 24, 33, 42</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Steel, Fire, Real Estate, Contract, Transport, Commissions</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 14, 23, 32, 41, 59, 15, 24, 33, 42</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Blue Sapphire, Golden Sapphire, Yellow Sapphire, Ruby, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Illness, Depression, Diabetes, Sinus Infection</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 1 && singleDigitTotal === 9) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Sun and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Mars, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1, 9, 5 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 9, 18, 27, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 9, 18, 27, 14, 23, 32, 41, 59, 15, 24, 33, 42, 6, 15, 24, 33, 42, 51, 60, 69, 78, 87, 96</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Steel, Fire, Real Estate, Contract, Transport, Commissions</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 9, 18, 27, 14, 23, 32, 41, 59, 15, 24, 33, 42, 6, 15, 24, 33, 42, 51, 60, 69, 78, 87, 96, 9, 18, 27, 36, 45, 54, 63, 72, 90, 99</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Blue, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Blue Sapphire, Golden Sapphire, Yellow Sapphire, Coral, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Illness, Depression, Diabetes, Sinus Infection</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

        // 2 to 1 numberology results

      }else if (dobTotal === 2 && singleDigitTotal === 1) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 7</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">7, 16, 25, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Goods, Nursery, Contract, Commission, Agriculture</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Golden Sapphire, Pearl, Cat's Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Over Heat, Piles, B.P, Eye Disease, Asthma, Mental Illness, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 2) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">7 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">7, 16, 25, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">34, 43, 15, 24, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Goods, Commission, Contract, Agriculture, Horticulture</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">34, 43, 15, 24, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Heat, Eye Disease, Piles, Hyper Tension, Mental Illness, Asthma, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 3) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">7 and 3</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">7, 16, 25, 3, 12, 21, 30, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 41, 50, 59, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Arts, Fine Arts, Books, Beauty Products, Utensils Factory</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 41, 50, 59, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Joint Pain, Diabetes, Constipation, Headache, Stomach Trouble, Heart Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
      }else if (dobTotal === 2 && singleDigitTotal === 4) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 7</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 2, 11, 20, 29, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Quarry, Exports, Leather, Beauty Products, Vehicles, Writer, Orator</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Green, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental, Piles, Arthritis, Back Pain, Diabetes, Gum Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 5) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">7 and 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">7, 16, 25, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Commission, Contracts, Beauty Products, Electrical, Green Items</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green and Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Nervous, Dental, Gas, Back Pain, Diabetes, Arthritis, Mental</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 6) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">7 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">7, 16, 25, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Agriculture, Vegetables, Contract, Perfumes, Jewelry, Beauty Products, Green Color Items</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red, Orange, Rose, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Jade, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Eye & Mental Diseases, Stomach Troubles, Arthritis, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 7) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 7</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 69, 34, 43</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Chemicals, Media, Dyes, Textiles, Hotel, Catering, Fine Arts</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 69, 34, 43</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Green, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl, Jade, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Hyper Tension, Gas, Arthritis, Diabetes, Mental, Psychosis</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 8) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Ketu, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">7, 5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">7, 16, 25, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Goods, Vehicles, Metal, Contract, Machines, Commission Agency, Arts</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Dark Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Back Pain, Migraine, Phobias, Stomach Pain, Venereal, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 2 && singleDigitTotal === 9) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Moon and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Ketu, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 15, 24, 33, 42, 51, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Agriculture, Teaching, Arbitration, Law, Commission, Military, Contracts</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 15, 24, 33, 42, 51, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Obesity, Hyper Tension</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

        //3 to 1 numberology results
       
      }else if (dobTotal === 3 && singleDigitTotal === 1) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className="table-head-9890">
                <tr className="table-tr-9890">
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">3 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 9, 18, 27, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">21, 39, 19, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Administration, Teaching, Metal, Fire, Stone, Wood, Super Market</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">21, 39, 19, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet, Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst, Yellow Sapphire, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Diabetes, Eye Disease, Hyper Tension</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 3 && singleDigitTotal === 2) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Jupiter, Mercury, and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">3, 7, and 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 7, 16, 25, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 21, 39, 14, 23, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Beauty Products, Commission, Contract</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst, Moon Stone, Cat’s Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Diabetes, Eye Disease, Hyper Tension, Skin Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }else if (dobTotal === 3 && singleDigitTotal === 3) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 9 and 3</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 9, 18, 27, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 73, 14, 23, 41, 50, 59, 27, 36, 45, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Dairy, Super Market, Commission, Contract</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 41, 50, 59, 27, 36, 45, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Diabetes, Gas, Hyper Tension</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Change Name In 45</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
      }else if (dobTotal === 3 && singleDigitTotal === 4) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">3 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">21, 39, 19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Arbitration, Law, Magazines, Auditing, Transport, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">21, 39, 19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet, Light Yellow and Blues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Arthritis, Diabetes, Gas, Hyper Tension, Chest, Head Aches</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 3 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      } else if (dobTotal === 3 && singleDigitTotal === 5) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">3, 5, and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 5, 14, 23, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 41, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Commission, Agency, Granite, Power, Metals</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet, Grey, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst, Diamond, Zircon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Arthritis, Diabetes, Hyper Tension, Skin Disease, Mental</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In Lucky Numbers</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }  else if (dobTotal === 3 && singleDigitTotal === 6) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">No Unlucky Days</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Goods, Agency, Textiles, Super Market</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">No Unlucky Color</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Golden Sapphire, Yellow and White Sapphires</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Sickness, Diabetes, Hyper Tension, Heart Disease, Arthritis, Eyes, Tooth Diseases</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In Lucky Numbers</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }  else if (dobTotal === 3 && singleDigitTotal === 7) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">3 and 2</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 2, 11, 20, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Liquids, Chemicals, Medical, Minerals</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst, Cat’s Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Sickness, Diabetes, Paralysis, Hyper Tension, Neuro Problems, Arthritis</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In Lucky Numbers</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
       
      }   else if (dobTotal === 3 && singleDigitTotal === 8) {
        setMessage(
          <div>
            <p>Your Ruling Planets: <b className="final-values">Jupiter and Saturn</b></p>
            <p>Your Lucky Planets: <b className="final-values">Jupiter and Mercury</b></p>
            <p>Your Lucky Numbers: <b className="final-values">3 and 5</b></p>
            <p>Your lucky Dates: <b className="final-values">3, 12, 21, 30, 5, 14, 23, 1, 10, 19</b></p>
            <p>Your Unlucky Days: <b className="final-values">6, 15, 24, 8, 17, 26</b></p>
            <p>Your Name Numbers For Lucky Names: <b className="final-values">21, 39, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64</b></p>
            <p>Your Lucky Business: <b className="final-values">Metals, Machine Shops, Transports, Iron, Journalism</b></p>
            <p>Name Numbers For Lucky Business: <b className="final-values">21, 39, 14, 23, 32, 41, 50, 59, 77, 9, 18, 27</b></p>
            <p>Your lucky Color: <b className="final-values">Rose, Orange, Violet, Blue, Yellow</b></p>
            <p>Your Unlucky Color: <b className="final-values">Black, Green</b></p>
            <p>Your lucky Stone: <b className="final-values">Amethyst, Blue Sapphire</b></p>
            <p>Your Diseases: <b className="final-values">Arthritis, Diabetes, Stomach Ailments, Paralysis, High BP</b></p>
            <p>Your Remedy Name Change In: <b className="final-values">Name Change In 3, 5, or 9</b></p>
          </div>
        );
       
      }   else if (dobTotal === 3 && singleDigitTotal === 9) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Jupiter and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Jupiter, Mercury, Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">3, 5, 1, 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 5, 14, 23, 1, 10, 19, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 8, 17, 26, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Metals, Machines, Armory, Transports, Iron, Journalism</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">21, 39, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet, Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Amethyst, Coral, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Arthritis, Diabetes, Stomach Ailments, Paralysis, High BP</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 3, 5, 1, or 9</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

        //4 t0 1 numberology results
       
      }  else if (dobTotal === 4 && singleDigitTotal === 1) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 73, 91, 19, 37, 46, 64, 73, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Oratory, Web Sites, Commission, Vehicles, Fire and Iron, Journalism</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 64, 73, 91, 19, 37, 46, 64, 73, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue and Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Garnet, Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Diabetes, Hyper Tension, Gas</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

      } else if (dobTotal === 4 && singleDigitTotal === 2) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 64, 73, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Web Sites, Transportation, Commission, Vehicles, Exports, Fire, Iron, Chemical Dealings</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 64, 73, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Yellow, Sandal, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Dark Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Garnet, Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Diabetes, High BP, Stomach Trouble, Back Pain, Sinus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">6 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
      }else if (dobTotal === 4 && singleDigitTotal === 3) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 3</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Medicine, Web Sites, 4 Wheelers, Vehicles, Food, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Yellow, Violet, Rose, Orange</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Garnet, Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Diabetes, High BP, Arthritis, Skin Disease, Allergy</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 4 && singleDigitTotal === 4) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Oratory, Web Sites, Writing, 4 Wheelers, Medical, Vehicles, Steel, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Yellow, Sandal</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Coffee Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Garnet and Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Diabetes, High BP, Arthritis, Kidney Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 4 && singleDigitTotal === 5) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Venus and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1, 6 and 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73, 15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Agency, Web Sites, Power, Commissions, Medical, Vehicles, Steel, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73, 15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Sandal, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Garnet, Diamond, Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Strokes, Paralysis, Diabetes, High BP, Arthritis, Sexual Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 4 && singleDigitTotal === 6) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fine Arts, Arbitration, Luxury & Beauty Goods, Vehicles, Steel, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">10, 19, 37, 46, 64, 73, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Light Blue Sapphire, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Strokes, Paralysis, Diabetes, High BP, Arthritis, Sexual Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 4 && singleDigitTotal === 7) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 10, 19, 37, 46, 64, 73</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fine Arts, Writing, www, Vehicles, Commission, Luxury Items</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 10, 19, 37, 46, 64, 73</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Coffee Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Light Blue Sapphire, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Strokes, Paralysis, Diabetes, Back Pain, High BP, Arthritis, Migraine, Tooth Ache</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 4 && singleDigitTotal === 8) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 41, 50, 59, 15, 24, 33, 42, 51, 60, 69, 10, 19, 37, 46, 64, 73</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Handicrafts, Vehicles, Commission, Iron, Arbitration</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 41, 50, 59, 15, 24, 33, 42, 51, 60, 69, 10, 19, 37, 46, 64, 73</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Blue, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Coffee Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Light Blue Sapphire, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Strokes, Paralysis, High BP, Arthritis, Diabetes, Back Pain</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 4 && singleDigitTotal === 9) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Rahu and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus, Sun and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 1 and 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 2, 11, 20, 29, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 64, 14, 23, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contracts, Brokerage, Vehicles, Commission, Iron, Arbitration</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 64, 14, 23, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Blue, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Coffee Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Light Blue Sapphire, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Strokes, Paralysis, Neuro Complaints, Diabetes, Back Pain, Arthritis</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

        // 5 to 1 numberology results
        
      }else if (dobTotal === 5 && singleDigitTotal === 1) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">No Such Days!</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64, 73, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Fire, Power, Steel</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64, 73, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Grey, Yellow, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Piles, Diabetes, Neurology Complaints, Hyper Tension, Heat</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 2) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 7</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Beauty Products, Electrical, Steel</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Grey, Light Green, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Tiger’s Eye, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Sinus, Cold, Piles, Asthma, High BP, Stomach Ailments</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 3) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 3 and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 3, 12, 21, 30, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Beauty & Cosmetics, Electrical, Steel Items</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Grey, Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Amethyst</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Sinus, Cold, Piles, Asthma, High BP, Stomach Ailments</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 4) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Sun, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 1, 10, 19, 28, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">No Such Days</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Steel</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Grey, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Nerve, Heart Disease, Diabetes, High BP</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5, 1 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 5) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 9, 18, 27, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">No Such Days</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 27, 36, 45, 54, 63, 72, 90</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Metals, Medicine, Web Sites</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 27, 36, 45, 54, 63, 72, 90</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Grey and All Colors</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">No Unlucky Colors</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Arthritis, Back Pain, Gum Disease, Diabetes, High BP</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5, or 9</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 6) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Luxury & Fancy Goods, Contract, Agencies, Metals, Commission, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Grey, Light Green, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Emerald</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Arthritis, Back Pain, Sexual Disease, Diabetes, High BP</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 7) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 2 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 2, 11, 20, 29, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Writing, Oratory, Contract, Agencies, Metals, Commission, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Grey, Green, Light Blue, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Illness, Gas, Eye, Ear, Throat Problems</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 5 && singleDigitTotal === 8) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">All Days Are Same</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Iron, Silver, Gold, Contract, Agencies, Metals, Commission, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">All Colors Same</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach, Back Pains, Eye, Ear, Throat Problems, High BP, Piles</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      } else if (dobTotal === 5 && singleDigitTotal === 9) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 9, 18, 27, 6, 15, 24, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 9, 18, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Iron, Silver, Gold, Contract, Agencies, Metals, Commission, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 77, 9, 18, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Red, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Coral</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Heart Disease, Arthritis, Back Pain, Diabetes, Neurological Issues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 9</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
        //6 to 1 numberology results
        
      }else if (dobTotal === 6 && singleDigitTotal === 1) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 55, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Iron, Silver, Gold, Contract, Agencies, Jewelry, Commission, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 55, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Orange, Rose, Violet, Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Heart Disease, Arthritis, Back Pain, Diabetes, Neurological Issues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 9</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 2) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, and 7</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 7, 16, 25, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Luxury Goods, Contract, Agencies, Jewelry, Commission, Vehicles, Green Colored Goods</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red, Orange, Rose, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Jade, Tiger’s Eye, Moon Stone, Pearl</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental, Diabetes, Skin Disease, Arthritis, Eye Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6 or 7</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 3) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Sun, Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">1, and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">1, 10, 19, 28, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">No Such Days</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Luxury Goods, Contract, Agencies, Commission, Textiles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">No Unlucky Color</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Yellow Sapphire, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental, Diabetes, Heart Disease, Arthritis, Gum, Eye Diseases, Hair Fall</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 1 or 9</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }
      else if (dobTotal === 6 && singleDigitTotal === 4) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Luxury Goods, Contract, Agencies, Commission, Textiles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 55, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Light Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet, Black and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Over Heat, Gas Troubles, Arthritis, Premature Baldness, Stomach Problems</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 5 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 5 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 5, 14, 23, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Luxury Goods, Contract, Agencies, Commission, Iron, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Blue, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">High BP, Amebiosis, Diabetes, Arthritis, Eye, Nerve Troubles</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6 or 5</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 6 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 9, 18, 27, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fancy Luxury Goods, Beauty, Cosmetics, Jewelry, Iron, Medicine, Green Color Goods</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 9, 18, 27, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Coral</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Over Heat Problems, High BP, Kidney Disease, Diabetes, Arthritis, Gum Troubles, Sex Issues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 1, 9 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 7 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 2</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 2, 11, 20, 29</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">119, 37, 46, 55, 64, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Writing, Speeches, Fancy Luxury Goods, Green Color Goods, Textiles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Light Blue, Light Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Jade</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Over Heat Problems, High BP, Kidney Disease, Diabetes, Arthritis, Gum Troubles, Sex Issues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 1, 9 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 8 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus, Mercury, and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 5, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 19, 37, 46, 55, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Fire, Power, Iron, Fancy Luxury Goods, Commission, Contracts</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 19, 37, 46, 55, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Green, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red, Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach Troubles, Constipation, Over Heat, High BP, Arthritis, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6, 5 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 6 && singleDigitTotal === 9 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Venus and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus, Mercury and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, and 9</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Commission, Contracts, Vehicles, Cosmetics, Fancy, Luxury Goods</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Green, Red, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Emerald, Coral</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Sexual Troubles, Piles, Over Heat, High BP, Arthritis, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6 or 9</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

        // 7 to 1 numberology results 
        
      }else if (dobTotal === 7 && singleDigitTotal === 1 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 2, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 2, 11, 20, 29, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Metals, Fire, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69, 19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Yellow, Blue, Sandal</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Moon Stone, Pearl, Cat’s Eye, Yellow Sapphire, Golden Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Meloncholy, Gum Disease, Heart, Stomach Ailments, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6, 1 or 2, 42 is Best For Cure</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 2 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 2</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 2, 11, 20</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69, 38, 56, 65</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Textiles, Fancy Goods, Writing and Oratory</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69, 56, 65</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Yellow And Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Melancholy, Arthritis, High BP, Heart Disease, Gas Troubles, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6, 1 or 2</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 3 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Moon, Mercury & Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">2, 5, and 3</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 5, 14, 23, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Profession, Trading, Liquid, Stone, Medicine, Arts, Contract, Agency</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 32, 41, 50, 59, 21, 39</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Yellow, And Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Gas, Melancholy, Over Heat, Neurological Diseases, Heart, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 3</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 4 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6 and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 1, 10, 19, 28, 2, 11, 20</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Arts, Religion, Oration, Writing, Commissions, Fancy Goods, Books, Paper</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Yellow, And Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red and Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach, Hip, Back Pains, Gum, Tooth Disease, Strokes, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 5 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Moon, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">2, 5, and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Speaking, Writing, Contracts, Medicine, Vehicles, Metal</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Yellow, Grey, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Sickness, Gas, Eye, Ear, Throat Diseases, Strokes, High BP, Diabetes, Back Pain</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Change</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 6 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Moon, Venus, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">2, 6, 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Speaking, Writing, Commissions, Contracts, Fancy Goods, Medicine, Green Items, Textiles, Vehicles, Transport</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">19, 37, 46, 55, 64, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Light Yellow, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Rose, Orange, Red, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye, Emerald</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Melancholy, Gas, Arthritis, Piles, ENT Diseases, Strokes, High BP, Diabetes, Back Pain</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 7 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Moon, Venus, Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">2, 6, 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 6, 15, 24, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Mediation, Writing, Law, Medicine, Commissions, Contracts, Electric, Electronic, Computer</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Light Blue, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Pearl, Tiger’s Eye, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Sickness, Dreams, Hip Pain, Nerve Disease, Arthritis, Eye Disease, Strokes, High BP, Diabetes, Back Pain</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 6 or 5, 42 Gives Early Relief</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 8 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus, Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">2, 5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Speaking, Writing, Commissions, Contracts, Medicine, Food, Electric, Electronic, Metal, Computer, Stationery</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Green, Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach, Chest, Gum, Mental Diseases, Stroke, High BP, Arthritis, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 6, 5, or 42</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 7 && singleDigitTotal === 9 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Ketu and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">None</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Mediator, Writing, Commissions, Contracts, Medicine, Medical, Iron, Steel, Metal</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Yellow, Light Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">None</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Sickness, Heart, Stomach, Gas, Constipation, Strokes, High BP, Diabetes, Arthritis</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 5 or 6 Series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 1 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6, 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Printing, Commission, Ad Agency, Electronic, Computer, Iron, Law, Religion</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Ruby, Emerald</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach Disease, Hip, Back Pains, Depression, Asthma, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 2 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Machines, Steel, Green Items, Fancy Goods, Vehicles, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Green, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Blue Diamond and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Cat’s Eye, Moon Stone, Tiger’s Eye, Blue Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Hip, Back, & Head Aches, Depression, Asthma, BP, Sexual Disease, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Correction In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 3 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Jupiter, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 3, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 3, 12, 21, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 86, 10, 19, 37, 46, 64, 73, 82, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Iron & Steel, Contract, Commission, Handicrafts, Arts, Transport, Printing</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 86, 10, 19, 37, 46, 64, 73, 82, 91</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Yellow, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Green and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Ruby, Diamond, Amethyst</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach Disease, Hip, Back, Pains, Depression, Asthma, High BP, Diabetes, Hernia, Tooth Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 4 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Sun, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 1, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 86, 19, 37, 46, 55, 64, 15, 24, 33, 42, 51, 60, 69, 78</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Iron & Steel, Writing, Speeches, Vehicles, Contracts, Commission Agencies</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 86, 15, 24, 33, 42, 51, 60, 69, 78, 19, 37, 46, 55, 64, 73, 82</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Dark Blues, Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Zircon, Garnet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Neurology Problems, Heart Trouble, Hyper Tension, Arthritis, Diabetes, Bladder, Kidney problems</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5, 6 or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 5 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Printing, Commission, Ad Agency, Electronic, Computer, Iron, Law, Religion</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Yellow, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Blue Sapphire, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Issues, Arthritis, Piles, Neurology, Eyes, Hip, Back Pains, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 6 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">3, 12, 21, 30, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 86, 15, 24, 33, 42, 51, 60, 69, 78, 10, 19, 37, 46, 55, 64, 73, 82</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Iron & Steel, Fir, Textiles, Power Projects, Luxuries, Fancy Goods, Contract, Commissions, Vehicles</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 86, 15, 24, 33, 42, 51, 60, 69, 78, 10, 19, 37, 46, 55, 64, 73, 82</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Yellow, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Dark Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Ruby, Emerald</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach Disease, Arthritis, Back Pains, Constipation, Skin Disease, BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Change In 5, 6, or 1</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 7 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Ketu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Printing, Commission Agency, Publishing, Writing, Iron and Steel</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Yellow, Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Dark Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Pearl, Cat’s Eye, Moon Stone</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Melancholy, Depression, Piles, Gum Disease, Stomach Pain, BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Correction In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 8 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6, and 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">4, 13, 22, 31, 7, 16, 25, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 24, 33, 42, 51, 60, 69, & 78</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Vehicles, Iron & Steel, Contracts, Commission, Writing & Speeches, Book Publications</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 24, 33, 42, 51, 60, 69, & 78</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Dark Blue, Black, and Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Diamond, Emerald, Yellow Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach Disease, Hip, Back Pains, Depression, Asthma, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Correction In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 8 && singleDigitTotal === 9 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Saturn and Mars</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5 and 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 9, 18, 27, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">8, 17, 26, 7, 16, 25, 2, 11, 20, 29</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Groceries, Super Markets, Iron, Metal, Vehicles, Contract, Commission, Beauty Items</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Blue Sapphire, Blue Aquamarine</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Stomach Disease, Hip, Back Pains, Gum Tooth Disease, Gas, Arthritis, Eye Disease, High BP, Diabetes</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Correction In 5 or 6</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        

        // 9 to 1 numberology results
        
      }else if (dobTotal === 9 && singleDigitTotal === 1 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Mercury, Sun, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">9, 5, 1, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 9, 18, 27, 1, 10, 19, 28</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">27, 45, 19, 37, 46, 64, 15, 24, 33, 42, 51, 60, 69, 5, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Paper, Commission, Metals, Fire, Writing, Publishing, Websites</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">27, 45, 19, 37, 46, 64, 15, 24, 33, 42, 51, 60, 69, 5, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black and Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Coral, Ruby, Nine Gems, Golden & Yellow Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Over Heat, Piles, Hip Pain, Neurology, Kidney Disease, Arthritis, Hyper Tensions</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 1, 5, 6, or 9 series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 9 && singleDigitTotal === 2 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Moon</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Venus, Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">No Unlucky Days</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 5, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Agriculture, Paper, Fancy & Green Items, Writing, Websites</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 5, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Blue</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">No Unlucky Colors</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Moon Stone, Cat’s Eye, Tiger’s Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Over Heat, Piles, Heart, Stomach Diseases, Bladder Complaints, Hyper Tension, Hip Pain, Kidney Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 5 or 6 series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 9 && singleDigitTotal === 3 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Jupiter</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Jupiter and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">9, 3, 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 5, 14, 23, 3, 12, 21, 30</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Construction, Commission, Metals, Writing, Oration, Oil, Vehicles, Super Markets, Malls</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">18, 27, 36, 45, 54, 21, 39, 5, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Yellow, Light Blue, Red, Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Coral, Amethyst, 9 Gems</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Neuro Problems, Gas, Hip Pain, Diabetes, Heat, Stomach Pain, High BP, Skin Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 5 or 9 series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 9 && singleDigitTotal === 4 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Rahu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Venus, Mercury, Sun</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">9, 6, 5, 1</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 5, 14, 23, 6, 15, 24, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">18, 27, 36, 45, 54, 15, 24, 33, 42, 51, 60, 69, 5, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Writing, Websites, Politics, Oratory</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">18, 27, 36, 45, 54, 15, 24, 33, 42, 51, 60, 69, 5, 14, 23, 32, 41, 50, 59, 77, 19, 37, 46, 64</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blues & Yellows, Red</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green, Black</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Coral, Garnet, 9 Gems, Light Blue Sapphire</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Heat, Piles, Heart, Stomach & Neuro Problems, BP, Diabetes, Hip, Back, Joint Pain, Kidney Disease</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Name Correction In 9, 6 or 5 Series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 9 && singleDigitTotal === 5 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 5, 14, 23, 6, 15, 24</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">27, 36, 45, 54, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Construction, Shipping, Agro Produce, Metal</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">27, 36, 45, 54, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Red, Grey</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Green</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Coral, Diamond, 9 Gems</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Skin, Stomach Diseases, Joint Pains, Arthritis, High BP, Heart Disease, Sinus, Cold, Bile</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 9 or 5 or 1 series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        
        
      }else if (dobTotal === 9 && singleDigitTotal === 6 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">9, 6, 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">9, 18, 27, 6, 15, 24, 5, 14, 23, 1, 10, 19</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">27, 36, 45, 54, 15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Commission, Fancy Goods, Shipping, Construction, Agro, Metal</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">27, 36, 45, 54, 15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Red, Light Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Dark Green, Rose, Orange, Violet</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Coral</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Diabetes, Dental Issues, High BP, Gastritis, Heart Disease, Urine & Piles, Back Problems</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 9 or 6 or 5 series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );  
      }else if (dobTotal === 9 && singleDigitTotal === 7 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars and Kethu</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Mercury</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">6, 5</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">6, 15, 24, 5, 14, 23</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">None</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Agencies, Commission, Vehicles, Super Market, Malls, Agro Produce, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">15, 24, 33, 42, 51, 60, 69, 14, 23, 32, 41, 50, 59, 77</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Light Blue, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">None</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Moon Stone, Cat’s Eye</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Mental Diseases, Joint Pains, Arthritis, Hyper Tension, Piles, Heart Disease, Back Pain, Eye Disease, Neurology Issues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">Fitting Name Correction In 9 or 6 or 5 series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }else if (dobTotal === 9 && singleDigitTotal === 8 ) {
        setMessage(
          <div className="container9890">
            <table className="info-table">
              <thead className='table-head-9890'>
                <tr className='table-tr-9890'>
                  <th className="subject-heading label">Subject Name</th>
                  <th className="lucky-items-heading label">Your Lucky Tips</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="label">Your Ruling Planets:</td>
                  <td className="value"><b className="final-values">Mars & Saturn</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Planets:</td>
                  <td className="value"><b className="final-values">Mars, Mercury, Venus</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Numbers:</td>
                  <td className="value"><b className="final-values">9, 5, 6</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Dates:</td>
                  <td className="value"><b className="final-values">5, 14, 23, 6, 15, 24, 9, 18, 27</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Days:</td>
                  <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25, 8, 17, 26</b></td>
                </tr>
                <tr>
                  <td className="label">Your Name Numbers For Lucky Names:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Business:</td>
                  <td className="value"><b className="final-values">Contract, Multiplex, Vehicles, Construction, Diaries, Agro Products, Iron</b></td>
                </tr>
                <tr>
                  <td className="label">Name Numbers For Lucky Business:</td>
                  <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69, 27, 36, 45, 54</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Color:</td>
                  <td className="value"><b className="final-values">Red, White, Yellow</b></td>
                </tr>
                <tr>
                  <td className="label">Your Unlucky Color:</td>
                  <td className="value"><b className="final-values">Black, Blue, Coffee Brown</b></td>
                </tr>
                <tr>
                  <td className="label">Your Lucky Stone:</td>
                  <td className="value"><b className="final-values">Coral, Ruby, Diamond</b></td>
                </tr>
                <tr>
                  <td className="label">Your Diseases:</td>
                  <td className="value"><b className="final-values">Tooth, Stomach Pains, Joint Pains, Arthritis, BP, Heart Disease, Asthma, Diabetes, Piles, Neurology Issues</b></td>
                </tr>
                <tr>
                  <td className="label">Your Remedy Name Change In:</td>
                  <td className="value"><b className="final-values">A Fitting Name Correction In 5, or 6, series</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }else if (dobTotal === 9 && singleDigitTotal === 9 ) {
        setMessage(
          <div className="container9890">
          <table className="info-table">
          <thead className='table-head-9890'>
          <tr className='table-tr-9890'>
            <th className="subject-heading label">Subject Name</th>
            <th className="lucky-items-heading label">Your Lucky Tips</th>
          </tr>
        </thead>
            <tbody>
              <tr>
                <td className="label">Your Ruling Planets:</td>
                <td className="value"><b className="final-values">Mars and Mars</b></td>
              </tr>
              <tr>
                <td className="label">Your Lucky Planets:</td>
                <td className="value"><b className="final-values">Mars, Venus, Mercury</b></td>
              </tr>
              <tr>
                <td className="label">Your Lucky Numbers:</td>
                <td className="value"><b className="final-values">9, 6, 5</b></td>
              </tr>
              <tr>
                <td className="label">Your Lucky Dates:</td>
                <td className="value"><b className="final-values">6, 15, 24, 5, 14, 23, 9, 18, 27</b></td>
              </tr>
              <tr>
                <td className="label">Your Unlucky Days:</td>
                <td className="value"><b className="final-values">2, 11, 20, 29, 7, 16, 25</b></td>
              </tr>
              <tr>
                <td className="label">Your Name Numbers For Lucky Names:</td>
                <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69, 27, 36, 45, 54</b></td>
              </tr>
              <tr>
                <td className="label">Your Lucky Business:</td>
                <td className="value"><b className="final-values">Contract, Agencies, Commission, Groceries, Construction, Mini Markets, Handicrafts, Art Related, Fire Arms, Ammunitions</b></td>
              </tr>
              <tr>
                <td className="label">Name Numbers For Lucky Business:</td>
                <td className="value"><b className="final-values">14, 23, 32, 41, 50, 59, 77, 15, 24, 33, 42, 51, 60, 69, 27, 36, 45, 54</b></td>
              </tr>
              <tr>
                <td className="label">Your Lucky Color:</td>
                <td className="value"><b className="final-values">Red, Blue, Yellow</b></td>
              </tr>
              <tr>
                <td className="label">Your Unlucky Color:</td>
                <td className="value"><b className="final-values">Green</b></td>
              </tr>
              <tr>
                <td className="label">Your Lucky Stone:</td>
                <td className="value"><b className="final-values">Coral</b></td>
              </tr>
              <tr>
                <td className="label">Your Diseases:</td>
                <td className="value"><b className="final-values">Gas, Stomach Diseases, Joint Pains, Arthritis, Hyper Tension, Piles, Heart Disease, Eye, Tooth Diseases</b></td>
              </tr>
              <tr>
                <td className="label">Your Remedy Name Change In:</td>
                <td className="value"><b className="final-values">Name Correction In 5, 6 or 9 Series</b></td>
              </tr>
            </tbody>
          </table>
        </div>
        );
      } else {
        setMessage(
          <div className="container9890">
             <table className="info-table">
             <tbody>
              <tr>
            <td className="value"><b className="final-values">Click to show more for more details about your Numberology</b></td>
            </tr>
            </tbody>
            </table>
          </div>
        );
      }
      setLoading(false);  // Hide the spinner after the delay
    }, 2000);  // 3 seconds delay
};
  const CharacterData = {
    'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'V': 6, 'Z': 7, 'F': 8,
    'I': 1, 'K': 2, 'L': 3, 'G': 4, 'H': 5, 'U': 6, 'O': 7, 'P': 8,
    'J': 1, 'R': 2, 'S': 3, 'T': 4, 'N': 5, 'W': 6,
    'Q': 1, 'M': 4, 'X': 5,
    'Y': 1,
  };
  const determinePlanet = (total) => {
    const planetMappings = {
        //Sun
        1: 'Sun',
        10: 'Sun',
        19: 'Sun',
        28: 'Sun',
        37: 'Sun',
        46: 'Sun',
        55: 'Sun',
        64: 'Sun',
        73: 'Sun',
        82: 'Sun',
        91: 'Sun',
        100: 'Sun',
        109: 'Sun',
        118: 'Sun',
        127: 'Sun',
        136: 'Sun',
        145: 'Sun',
        154: 'Sun',
        163: 'Sun',
        172: 'Sun',
        181: 'Sun',
        190: 'Sun',
        //Moon
        2: 'Moon',
        11: 'Moon',
        20: 'Moon',
        29: 'Moon',
        38: 'Moon',
        47: 'Moon',
        56: 'Moon',
        65: 'Moon',
        74: 'Moon',
        83: 'Moon',
        92: 'Moon',
        101: 'Moon',
        110: 'Moon',
        119: 'Moon',
        128: 'Moon',
        137: 'Moon',
        146: 'Moon',
        155: 'Moon',
        164: 'Moon',
        173: 'Moon',
        182: 'Moon',
        191: 'Moon',
        //Jupiter
        3: 'Jupiter',
        12: 'Jupiter',
        21: 'Jupiter',
        30: 'Jupiter',
        39: 'Jupiter',
        48: 'Jupiter',
        57: 'Jupiter',
        66: 'Jupiter',
        75: 'Jupiter',
        84: 'Jupiter',
        93: 'Jupiter',
        102: 'Jupiter',
        111: 'Jupiter',
        120: 'Jupiter',
        129: 'Jupiter',
        138: 'Jupiter',
        147: 'Jupiter',
        156: 'Jupiter',
        165: 'Jupiter',
        174: 'Jupiter',
        183: 'Jupiter',
        192: 'Jupiter',
        //Rahu
        4: 'Rahu',
        13: 'Rahu',
        22: 'Rahu',
        31: 'Rahu',
        40: 'Rahu',
        49: 'Rahu',
        58: 'Rahu',
        67: 'Rahu',
        76: 'Rahu',
        85: 'Rahu',
        94: 'Rahu',
        103: 'Rahu',
        112: 'Rahu',
        121: 'Rahu',
        130: 'Rahu',
        140: 'Rahu',
        148: 'Rahu',
        157: 'Rahu',
        166: 'Rahu',
        175: 'Rahu',
        184: 'Rahu',
        193: 'Rahu',
        //Mercury
        5: 'Mercury',
        14: 'Mercury',
        23: 'Mercury',
        32: 'Mercury',
        41: 'Mercury',
        50: 'Mercury',
        59: 'Mercury',
        68: 'Mercury',
        77: 'Mercury',
        86: 'Mercury',
        95: 'Mercury',
        104: 'Mercury',
        113: 'Mercury',
        122: 'Mercury',
        131: 'Mercury',
        141: 'Mercury',
        149: 'Mercury',
        158: 'Mercury',
        167: 'Mercury',
        176: 'Mercury',
        185: 'Mercury',
        194: 'Mercury',
        //Venus
        6: 'Venus',
        15: 'Venus',
        24: 'Venus',
        33: 'Venus',
        42: 'Venus',
        51: 'Venus',
        60: 'Venus',
        69: 'Venus',
        78: 'Venus',
        87: 'Venus',
        96: 'Venus',
        105: 'Venus',
        114: 'Venus',
        123: 'Venus',
        132: 'Venus',
        142: 'Venus',
        150: 'Venus',
        159: 'Venus',
        168: 'Venus',
        177: 'Venus',
        186: 'Venus',
        195: 'Venus',
        //Ketu
        7: 'Ketu',
        16: 'Ketu',
        25: 'Ketu',
        34: 'Ketu',
        43: 'Ketu',
        52: 'Ketu',
        61: 'Ketu',
        70: 'Ketu',
        79: 'Ketu',
        88: 'Ketu',
        97: 'Ketu',
        106: 'Ketu',
        115: 'Ketu',
        124: 'Ketu',
        133: 'Ketu',
        143: 'Ketu',
        151: 'Ketu',
        160: 'Ketu',
        169: 'Ketu',
        178: 'Ketu',
        187: 'Ketu',
        196: 'Ketu',
        //Saturn
        8: 'Saturn',
        17: 'Saturn',
        26: 'Saturn',
        35: 'Saturn',
        44: 'Saturn',
        53: 'Saturn',
        62: 'Saturn',
        71: 'Saturn',
        80: 'Saturn',
        89: 'Saturn',
        98: 'Saturn',
        107: 'Saturn',
        116: 'Saturn',
        125: 'Saturn',
        134: 'Saturn',
        144: 'Saturn',
        152: 'Saturn',
        161: 'Saturn',
        170: 'Saturn',
        179: 'Saturn',
        188: 'Saturn',
        197: 'Saturn',
        //Mars
        9: 'Mars',
        18: 'Mars',
        27: 'Mars',
        36: 'Mars',
        45: 'Mars',
        54: 'Mars',
        63: 'Mars',
        72: 'Mars',
        81: 'Mars',
        90: 'Mars',
        99: 'Mars',
        108: 'Mars',
        117: 'Mars',
        126: 'Mars',
        135: 'Mars',
        145: 'Mars',
        153: 'Mars',
        162: 'Mars',
        171: 'Mars',
        180: 'Mars',
        189: 'Mars',
        198: 'Mars',
    };
    return planetMappings[total] || 'Unknown Planet';
  };
function createBoxes() {
    const container = document.getElementById('container');
    container.innerHTML = '';
    const planet = document.getElementById('planet').value;
    const planetNumbers = {
        'Sun': [6, 1, 8, 7, 5, 3, 2, 9, 4],
        'Mars': [8, 3, 10, 9, 7, 5, 4, 11, 6],
        'Ketu': [14, 6, 16, 15, 13, 11, 10, 17, 12],
        'Moon': [7, 2, 9, 8, 6, 4, 3, 10, 5],
        'Jupiter': [10, 5, 12, 11, 9, 7, 6, 13, 8],
        'Satern': [12, 7, 14, 13, 11, 9, 8, 15, 10],
        'Mercury': [9, 4, 11, 10, 8, 6, 5, 12, 7],
        'Venus': [11, 6, 13, 12, 10, 8, 7, 14, 9],
        'Rahu': [13, 8, 15, 14, 12, 10, 6, 16, 11],
    };
    const numbers = planetNumbers[planet];
    for (let i = 0; i < numbers.length; i++) {
        const box = document.createElement('div');
        box.className = 'box';
        box.textContent = numbers[i];
        container.appendChild(box);
    }};
  const handleInputChange = (event) => {
    const inputName = event.target.value.toUpperCase();
    let total = 0;
    for (let i = 0; i < inputName.length; i++) {
      const char = inputName[i];
      if (CharacterData[char]) {
        total += CharacterData[char];
      }
    }
    setName(inputName);
    setTotalValue(total);
  };
  const getAstrologicalPrediction = (planet) => {
    switch (planet) {
      case 'Sun':
        return 'Litigation, loss of money, and challenges are foreseen. Consider consulting an astrologer for remedies.';
      case 'Moon':
        return 'Emotional ups and downs may be expected. A change of name could bring positive transformations.';
      default:
        return 'Astrological insights for this planet are not available.';
    }
  };
  function updateGraph(inputIndex, value) {
    const maxHeightPercentage = 100;
    const heightPercentage = Math.min(maxHeightPercentage, Math.max(0, value));
    const graphBar = document.querySelectorAll('.graph-container .graph-bar')[inputIndex];
    graphBar.style.height = `${heightPercentage}%`;
    const graphBarValue = document.createElement('div');
    graphBarValue.className = 'graph-bar-value';
    graphBarValue.textContent = value;
    graphBar.appendChild(graphBarValue);
}
function addGraph() {
    const inputNumber = document.getElementById('input-field');
    const values = inputNumber.value.split(' ').map(parseFloat);

    const graphContainer = document.getElementById('graph-container');

    values.forEach((value, index) => {
        const newGraphBar = document.createElement('div');
        newGraphBar.className = 'graph-bar';
        graphContainer.appendChild(newGraphBar);

        updateGraph(index, value);
    });
}
  return (
    <div>
<div className='main-div-input-box'>
    <div className='sec-main-input-box-9890'>
    <div className='main-box-input-name'>
      <label>
        <input
          type="text"
          value={name}
          onChange={handleInputChange}
          placeholder="Enter your name"
          className="input-name"
        />
        <p className="name-total-input name-total-input-9890">{totalValue}</p>
      </label>
      </div>
     <div className='main-box-input-dob'>
        <label className="lable-check">
          <input
            type="text"
            placeholder="Day"
            className="input-DOB input-dob-left009"
            value={day}
            onChange={handleDayChange}
          />
        </label>
        <label className="lable-check">
          <input
            type="text"
            placeholder="Month"
            className="input-DOB"
            value={month}
            onChange={handleMonthChange}
          />
        </label>
        <label className="lable-check">
          <input
            type="text"
            placeholder="Year"
            className="input-DOB"
            value={year}
            onChange={handleYearChange}
          />
        </label>
     </div>
<div className="Box">
          <select id="planet" onChange={createBoxes}>
              <option value="">Select your yantra</option>
              <option value="Sun">Sun Yantra</option>
              <option value="Mars">Mars Yantra</option>
              <option value="Ketu">Ketu Yantra</option>
              <option value="Moon">Moon Yantra</option>
              <option value="Jupiter">Jupiter Yantra</option>
              <option value="Satern">Saturn Yantra</option>
              <option value="Mercury">Mercury Yantra</option>
              <option value="Venus">Venus Yantra</option>
            <option value="Rahu">Rahu Yantra</option>
        </select>
</div>
    <div className="input-container" id="input-container">                                     
      <input type="text" id="input-field" placeholder="Enter values separated by space" />
      <button onClick={addGraph} className='graph-btn'>Make Graph </button>
    </div>
    <div className='dash9890-btn-box'>
      <button  className='nam-dob-submit-btn' onClick={handleSubmit}>Submit</button>
      <button className='nam-dob-submit-btn2' onClick={printPage}>Print & PDF</button>
      <button className='nam-dob-submit-btn3' onClick={handleReload}>Reset Page</button>
    </div>
    </div>
</div>
{loading && (
  <div className="spinner2">
    <div className="spinner-container2">
      <div className="spinner-ring2"></div>
    </div>
  </div>
)}
{showResults && totalValue !== null && (
  <div>
    <p className="main-names-page mmdtextname">
      <div>
        <h3 className="main-big-title mmdaswebnam">
          <img src="/images/NUMBEROLOGY-header-logo.png" alt="Not Found" />
        </h3>
      </div>
      <p className='top-title-NRBSA'>Numerological Results By Sandeeip Arya</p>
    </p>
  </div>
)}   
  <div className="main-check-name main-check-name-9890 NR">
{showNameResults && totalValue !== null && (
  <table className="info-table">
  <tbody >
    {name && (
      <tr>
        <td className="label">Name</td>
        <td className="value"><b className="final-values">{name}</b></td>
      </tr>
    )}
    {day && month && year && (
      <tr>
        <td className="label">Date of birth</td>
        <td className="value"><b className="final-values">{day}/{month}/{year}</b></td>
      </tr>
    )}
    {totalValue !== null && (
      <tr>
        <td className="label">Total character value of your name</td>
        <td className="value"><b className="final-values">{totalValue}</b></td>
      </tr>
    )}
    {totalValue !== null && (
      <tr>
        <td className="label">Your Corresponding planet</td>
        <td><b className="final-values">{determinePlanet(totalValue)}</b></td>
      </tr>
    )}
    {totalValue !== null && (
      <tr>
        <td className="label">Astrological insights</td>
        <td className="value"><b className="final-values">{getAstrologicalPrediction(determinePlanet(totalValue))}</b></td>
      </tr>
    )}
  </tbody>
</table>
)}      
{showResults && (
    <div>         
    <table className="info-table">
  <tbody>
    {dateMonthYearTotal !== null && (
      <tr>
        <td className="label">Your Birth Number and Destiny Number</td>
        <td className="value"><b className="final-values">{dateMonthYearTotal}</b></td>
      </tr>
    )}
    {dobTotal !== null && (
      <tr>
        <td className="label">Your Radical Number</td>
        <td className="value"><b className="final-values">{dobTotal}</b></td>
      </tr>
    )}
    {singleDigitTotal !== null && (
      <tr>
        <td className="label">Your Naamank</td>
        <td className="value"><b className="final-values">{singleDigitTotal}</b></td>
      </tr>
    )}
    {dobTotal !== null && singleDigitTotal !== null && (
      <tr>
        <td className="label">Numerology Compatibility Lucky Tips For</td>
        <td className="value"><b className="final-values">{dobTotal} & {singleDigitTotal}</b></td>
      </tr>
    )}
  </tbody>
</table>          
      {message && <div>{message}</div>}
       <button  className='nam-dob-submit-btn nam-dob-submit-btn24' onClick={handleSubmit}>Show More</button>
  <div className='main-9890-p-box-gem'>
        <div className='image2-main-div-9890'>
          <p className='not-bdottom yy-none'><h2 className='main-big-title nr-lp-none-9890'>Your Yantra - </h2></p>
            <div className="main-box">
              <div className="square" id="container" />
            </div>
        </div>
  </div>
  <div className='main-graph-section-9890'>                                  
      <div className='main-check-name23'> 
        <div>
          <p className='not-bdottom yy-none'><h2 className='main-big-title'>Numerological Life Graph</h2></p>
          <p className='not-bdottom yy-none'><h4 className='main-big-title2'> Benific Age</h4> </p>
          <div className="graph-container" id="graph-container"/>                                        
        </div>
      </div>
  </div>
</div>
)}
    </div>    
    </div>    
  );
};
export default DateValueCalculator;