import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';

const CharacterData = {
    'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'V': 6, 'Z': 7, 'F': 8,
    'I': 1, 'K': 2, 'L': 3, 'G': 4, 'H': 5, 'U': 6, 'O': 7, 'P': 8,
    'J': 1, 'R': 2, 'S': 3, 'T': 4, 'N': 5, 'W': 6,
    'Q': 1, 'M': 4, 'X': 5,
    'Y': 1,
};

const determinePlanet = (total) => {
    const planetMappings = {
        1: 'Sun',
        10: 'Sun',
        19: 'Sun',
        28: 'Sun',
        37: 'Sun',
        46: 'Moon',
        55: 'Moon',
        64: 'Moon',
        73: 'Moon',
        29: 'Moon',
        // Add more mappings as needed
    };

    return planetMappings[total] || 'Unknown Planet';
};

const getDobPrediction = (dobTotal) => {
    switch (dobTotal) {
        case 1:
            return 'Dogmas are part of your life.';
        case 2:
            return 'Something else for value 2.';
        // Add more cases for other values as needed
        default:
            return 'Astrological insights for this value are not available.';
    }
};

const NameAnalyzer = () => {
    const [name, setName] = useState('');
    const [totalValue, setTotalValue] = useState(null);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [dobTotal, setDobTotal] = useState(null);
    const [dateMonthYearTotal, setDateMonthYearTotal] = useState(null);
    const [gender, setGender] = useState('male'); // Default to 'male'



    const handleInputChange = (event) => {
        const inputName = event.target.value.toUpperCase();
        let total = 0;

        for (let i = 0; i < inputName.length; i++) {
            const char = inputName[i];
            if (CharacterData[char]) {
                total += CharacterData[char];
            }
        }

        setName(inputName);
        setTotalValue(total);
    };

    const handleDayChange = (event) => {
        setDay(event.target.value);
        calculateDobTotal();
        calculateDateMonthYearTotal();
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        calculateDobTotal();
        calculateDateMonthYearTotal();
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        calculateDobTotal();
        calculateDateMonthYearTotal();
    };

    const calculateDobTotal = () => {
        // Assuming the date of birth inputs are valid integers
        const dayTotal = day.split('').reduce((acc, digit) => acc + parseInt(digit), 0);
        setDobTotal(dayTotal);
    };

    const calculateDateMonthYearTotal = () => {
        const dayTotal = [...day].reduce((acc, digit) => acc + parseInt(digit), 0);
        const monthTotal = [...month].reduce((acc, digit) => acc + parseInt(digit), 0);
        const yearTotal = [...year].reduce((acc, digit) => acc + parseInt(digit), 0);
        const total = dayTotal + monthTotal + yearTotal;

        setDateMonthYearTotal(total);
    };

    const getAstrologicalPrediction = (planet) => {
        switch (planet) {
            case 'Sun':
                return 'Litigation, loss of money, and challenges are foreseen. Consider consulting an astrologer for remedies.';
            case 'Moon':
                return 'Emotional ups and downs may be expected. A change of name could bring positive transformations.';
            // Add more cases for other planets as needed
            default:
                return 'Astrological insights for this planet are not available.';
        }
    };


    useEffect(() => {
        // Save to local storage when name, gender, or related information changes
        localStorage.setItem('names', JSON.stringify(getStoredNames()));
        localStorage.setItem('gender', gender);
        // ... (you can add more items to store in local storage)
    }, [name, gender, day, month, year, dobTotal, dateMonthYearTotal]);

    const getStoredNames = () => {
        const storedNames = JSON.parse(localStorage.getItem('names')) || [];
        return [...new Set(storedNames)]; // Remove duplicates using Set
    };

    const saveName = () => {
        if (name.trim() !== '') {
            const storedNames = getStoredNames();
            storedNames.push(name);
            localStorage.setItem('names', JSON.stringify(storedNames));
        }
    };





    return (
        <Layout>
            <div className='main-check-your-banner-main'>
            <img className='chek-yours-banImg'  src='\images\NUMBEROLOGY-chek-banner.png' alt='Not Found'/>
            </div>

            <div className='main-main-check-page'>
            <div className='main-check'>
                <div className='main-check-name NR'>
                    <div className='check-yours-title'>Check Your</div>
                    <div className='check-title-name'>Name Numerology</div>

                    <div className='check-info'>This is an interesting section! Type in your name in the box provided below and we
                        will tell you your name number and interesting facts about your name number</div>

                    <label>
                        <input type="text" value={name} onChange={handleInputChange} placeholder=' Enter your name' className='input-name' />

                        <p className='name-total-input'> {totalValue}</p>
                    </label>

                    {/* <div className='save-gender-name-localstor' >
                        <select value={gender} onChange={(e) => setGender(e.target.value)}  >
                            <option value="male">Male</option>
                            <option value="">Female</option>
                        </select>

                        <button onClick={saveName} className='name-save-btn'>Submit</button>
                    </div> */}


                    {totalValue !== null && (
                      <div>
                      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                          <tbody>
                              <tr style={{ border: '1px solid Gray' }}>
                                  <td style={{ border: '1px solid Gray', padding: '8px' }}>Total character value of your name</td>
                                  <td style={{ border: '1px solid Gray', padding: '8px' }}><b className='final-values'>{totalValue}</b></td>
                              </tr>
                              <tr style={{ border: '1px solid Gray' }}>
                                  <td style={{ border: '1px solid Gray', padding: '8px' }}>Corresponding planet</td>
                                  <td style={{ border: '1px solid Gray', padding: '8px' }}><b className='final-values'>{determinePlanet(totalValue)}</b></td>
                              </tr>
                              <tr style={{ border: '1px solid Gray' }}>
                                  <td colSpan="2" style={{ border: '1px solid Gray', padding: '8px' }} className='higlite-contant'>The interpretations for your name number are:</td>
                              </tr>
                              <tr style={{ border: '1px solid Gray' }}>
                                  <td style={{ border: '1px solid Gray', padding: '8px' }}>Astrological insights</td>
                                  <td style={{ border: '1px solid Gray', padding: '8px' }}><b className='final-values'>{getAstrologicalPrediction(determinePlanet(totalValue))}</b></td>
                              </tr>
                              <tr style={{ border: '1px solid black' }}>
                                  <td colSpan="2" style={{ border: '1px solid Gray', padding: '8px' }}>For detailed readings regarding your name numbers consult the book or the Author</td>
                              </tr>

                              <tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Ruling Planets:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Rahu and Sun</td>
</tr>
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Lucky Planets:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>	Sun and Venus</td>
</tr>
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Lucky Numbers:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>1, 10, 19, 28, 6, 15, 24</td>
</tr>
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Lucky Business:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Oratory, Web Sites, Commission, Vehicles, Fire and Iron, Journalism</td>
</tr>
                          </tbody>
                      </table>
                  </div>
                    )}
                </div>

                <div className='main-check-name NR'>
                    <div className='check-title-name'>Birth Number Numerology  </div>
                    <div className='check-info'>Another section that will classify your birth number and give you interesting information about the number</div>


                    <div className='check-info'>Enter Your Date of Birth</div>
                    <label className='lable-check'>
                        <input type="text" placeholder="Day" className='input-DOB' value={day} onChange={handleDayChange} onBlur={calculateDateMonthYearTotal} />
                    </label>
                    <label className='lable-check'>
                        <input type="text" placeholder="Month" className='input-DOB' value={month} onChange={handleMonthChange} onBlur={calculateDateMonthYearTotal} />
                    </label>
                    <label className='lable-check'>
                        <input type="text" placeholder="Year" className='input-DOB' value={year} onChange={handleYearChange} onBlur={calculateDateMonthYearTotal} />
                    </label>
                    {dateMonthYearTotal !== null && (
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom: '10px' }}>
                            <tbody >
                            <tr style={{ border: '1px solid Gray' }}>
                            <td style={{ border: '1px solid Gray', padding: '8px' }}>Your Birth Number and Destiny Number:</td>
                            <td style={{ border: '1px solid Gray', padding: '8px' }}><b className='final-values'>{dateMonthYearTotal}</b></td>
                            </tr>
                            </tbody>
                        </table>
                    )}
                    {dobTotal !== null && (
                               <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                               <tbody>
                                   <tr style={{ border: '1px solid Gray' }}>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}>Your Birth Number and Psychic Number</td>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}><b className='final-values'>{dobTotal}</b></td>
                                   </tr>
                                   <tr style={{ border: '1px solid Gray' }}>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}>Astrological insights based on date of birth</td>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}><b className='final-values'>{getDobPrediction(dobTotal)}</b></td>
                                   </tr>
                                   {/* Blur contant */}
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Ruling Planets:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Rahu and Sun</td>
</tr>
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Lucky Planets:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>	Sun and Venus</td>
</tr>
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Lucky Numbers:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>1, 10, 19, 28, 6, 15, 24</td>
</tr>
<tr style={{border: '1px solid Gray',userSelect: 'none', MozUserSelect: 'none',  WebkitUserSelect: 'none', }}className='blur-chek-content'>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Your Lucky Business:</td>
    <td style={{border: '1px solid Gray',padding: '8px',userSelect: 'none',MozUserSelect: 'none',WebkitUserSelect: 'none',}}>Oratory, Web Sites, Commission, Vehicles, Fire and Iron, Journalism</td>
</tr>

<tr style={{ border: '1px solid Gray' }}>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}>To improve your life and fortune, consult the Author Mr. Sandeeip Arya</td>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}>
                                           <a className='check-page-contact-option' href='/contact'>Contact Now</a>
                                       </td>
                                   </tr>
                                   {/* <tr style={{ border: '1px solid Gray' }}>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}>To know more details</td>
                                       <td style={{ border: '1px solid Gray', padding: '8px' }}>
                                           <a className='check-page-contact-option' href='/razorpaypayment'>Click here</a>
                                       </td>
                                   </tr> */}

                               </tbody>
                           </table>
                    )}

                </div>
            </div>

            <div className='main-check-contant-box'>
            <div className='check-yours-title2'>How do you calculate your name in numerology?</div>
            <p className='check-your-content2'>Numerology numbers are a sum value of the name numbers, which vibrate various energies and expressions. The digits from 1 to 8 are assigned for alphabets in the Chaldean system. The digit nine is omitted during calculation of Chaldean name number numerology. Just add these alphabets' number numerology as mentioned below.</p>

            <div className='check-yours-title2'>How can you calculate the numerology numbers?</div>
            <p className='check-your-content2'>Numerology has some types of numbers, such as a life path, destiny, heart desire etc. Each type of number can be derived from a specific method. For the name numerology number, you can calculate the numerology number with the above tool or manually from the number chart. For doing it yourself use the methods, like the examples given below. For the prediction of your name numbers, you can still use the calculator. It does not store your information, anywhere.</p>
            
            </div>
            </div>
            <br/>
            <br/>
            <br/>
        </Layout>
    );
};

export default NameAnalyzer;
