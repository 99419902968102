import React from 'react'
import Layout from '../components/Layout/Layout'

const About = () => {
    return (
        <Layout>
<img className='about-page-banner' src='\images\NUMBEROLOGY-33-banner.png' alt='Not Found'/>

<div className='main-title'><div className='title'>About Us</div></div>
<div className='main-title-down-image'><img src='\images\title-border.webp' alt='Not Found'/></div>

<div className='main-justcall-container'>
The good, the bad, the worse; all of them are meant to pass one day. No situation stays forever. It isn’t in your hands to control every situation of every cell on the planet, all you could and should do is work according to your ultimate purpose. If it’s good, then it can be made better. If it’s bad, then it can be made easier. All through the power of the science of astrology. Believe in yourselves and your stars.
<br/>
<br/>
The modern apocalypse is that astrology has been led to be believed as a mere sham. The ignorance of the science behind astrology has led to this condition. Astroarunpandit’s main motto is to establish back faith in astrology predictions. In the puranic times, as well as the modern era; astrology plays an important role in everyone’s life. This science is one of the most ancient ones among humanity. The legacy of astrology is such that, even today the auspicious tasks of one’s life are done according to planetary movements known as mahurat.
</div>

<div className='Your-Guide-To-Life'>
<div className='your-guide-text-secton'>
<p className='your-guide-text-title'>Have Life Struggles? We At Numberology Is The Answer</p>
<p className='your-guide-text-content'><b>Love astrology</b> – In pain to let go of someone? Not finding good marriage prospects? Have difficulty in relationships? Numberology.in has got all the solutions for your troubles in love and relationships.</p>
<p className='your-guide-text-content'><b>Health astrology</b> – Struggling to get a grip on your health? Not able to untangle yourself from the loop of hospitals, medicines, and doctors? Numberology.in will help your find answers to achieve your optimum health and body.</p>
<p className='your-guide-text-content'><b>Wealth astrology</b> – Is money the root of all your problems? Finding difficulties with money at all times? Not able to get your loved ones the gifts they deserve? Don’t worry, Numberology.in will remove all the obstacles from the path of you attaining money and Dhanalaxmi</p>
<p className='your-guide-text-content'><b>Career astrology</b> – Not able to get that government job? Struggling to get into the ranks of executives and officers? We have the perfect solutions for your career difficulties.</p>
</div>

<div className='your-guide-image-section'>
<img src='\images\Graphic-1.png' alt='Not Found'/>
</div>
</div>

<div className='Your-Guide-To-Life'>
<div className='your-guide-text-secton'>
<p className='your-guide-text-title'>Who Do We Believe In?</p>
<p className='your-guide-text-content'>The greatest of individuals like Chanakya, Aryabhatta, Brahmagupta, and many more have been graced with the blessing of astrology knowledge. Numberology.in believes that when these legendary sages believed in the power of astrology then why can’t it be used to empower present generations. We believe that with proper guidance, expertise, and consultation of our experts, you can become the most successful version of yourself. When you do everything according to astrological sciences then no one can drive you away from your path to success because your success is meant to be.</p>
</div>

<div className='your-guide-image-section'>
<img src='\images\Graphic-2.png' alt='Not Found'/>
</div>
</div>

<div className='Your-Guide-To-Life'>
<div className='your-guide-text-secton'>
<p className='your-guide-text-title'>Why Numberology ?</p>
<p className='your-guide-text-content'>Our experts are qualified and trained in such a manner, that they will guide you to solve any query you have. Be it money, relationships, work, health, or anything else. The core of Numberology.in lies in building long-term relations with our clients. Our consultation is personalized for every individual. For making astrology predictions, we track and know about all your actions, thinking, mentality and capability. This enables us to consult you to your optimum self.  We have experts assigned to track your horoscope in such a manner that you are made aware of every minor and major movement and consult you as per them.</p>
</div>

<div className='your-guide-image-section'>
<img src='\images\Graphic-1.png' alt='Not Found'/>
</div>
</div>
        </Layout>
    )
}

export default About
