import React from 'react';

const RazorpayPayment = () => {
    const handlePayment = () => {
        if (!window.Razorpay) {
          console.error("Razorpay SDK not loaded.");
          return;
        }
      
        const options = {
          key: 'rzp_test_rXjqjNPrmPQyah',
          amount: 999 * 100,
          currency: 'INR',
          name: 'Numberology.com',
          description: 'Test Transaction',
          handler: function (response) {
            if (response.razorpay_payment_id) {
              window.location.href = '/dashboard9890';
            }
          },
          prefill: {
            name: 'Your Full Name',
            email: 'customer@example.com',
            contact: '9999999999',
          },
          theme: {
            color: '#99155e',
          },
        };
      
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      };

  return (
    <div>
      <h1>Make a Payment</h1>
      <button onClick={handlePayment}>Pay ₹999</button>
    </div>
  );
};

export default RazorpayPayment;
